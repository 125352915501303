

main.gray-bg {
  background-color: rgb(243, 243, 243);
  padding: 0 0 100px 0;
  h1 {
    text-align: center;
    margin: 20px;
    color: $mainBlue;
  }
}

//nesting separates css for blog
main.blog-css {
  margin-left: auto;
  margin-right: auto;
  max-width: 40rem;
  padding: 0 20px 150px 20px;

  header * {
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  header .dmode {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: right;
  }

  header .dmode img {
    cursor: pointer;
    display: inline-block;
  }

  header section {
    display: flex;
    margin: 0 0 4.375rem 0;
    justify-content: space-between;
    align-items: flex-start;
  }

  section img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin: 0.5rem 1rem 0 0;
  }

  a {
    color: #2782cd;
    border-bottom: 1px solid #2782cd;
  }

  a:hover {
    border-bottom: none;
  }

  /*.posts {
    margin-top: 4rem;
 }*/

  .posts h3 {
    font-weight: 900;
    text-rendering: optimizeLegibility;
    font-size: 1.4427rem;
    line-height: 1.1;
    margin-top: 3.5rem;
  }

  .posts small {
    margin-top: 0.6rem;
    font-size: 100%;
    color: $mainTextColor;
    display: block;
    position: relative;
  }

  .post p {
    font-size: 1.05rem;
    margin: 4px auto;
    color: $mainBlack;
  }

  .post-category {
    background-color: $mainBlack;
    color: $categoryTextColor;
    display: inline-block;
    margin-top: 5px;
    padding: 5px;
    font-size: 90%;
    border-radius: 5px;
    width: auto;
  }

  .meta .date {
    font-size: 100%;
    font-style: normal;
  }
  .sub-meta {
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .column {
      margin: 0 10px 10px 0;
    }
    .date {
      width: auto;
    }
    .post-category {
      margin-top: 0;
    }
  }
  .meta .title {
    margin-bottom: 0.2rem;
    color: $mainBlue;
  }
  .meta .post-author {
    width: auto;
    a.author-name {
      display: inline;
      font-weight: 500;
      border-bottom: none;
    }
  }
  /* post page */
  header.p-page * {
    font-weight: 900;
    text-rendering: optimizeLegibility;
    font-size: 1.4427rem;
    line-height: 1.1;
  }

  header.p-page {
    margin-bottom: 1.75rem;
  }

  article .title {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    color: inherit;
    font-family: Montserrat, sans-serif;
    font-weight: 900;
    text-rendering: optimizeLegibility;
    font-size: 2.5rem;
    line-height: 1.1;
  }

  article .meta {
    margin-bottom: 1.75rem;
  }

  .content p {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    font-size: 1.1rem;
    color: $mainBlack;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.75rem;
    line-height: 1.7rem;
  }

  /* md syntax  */

  .content h1 {
    font-size: 2rem;
  }
  .content h2 {
    font-size: 1.8rem;
  }
  .content h3 {
    font-size: 1.6rem;
  }
  .content h4 {
    font-size: 1.4rem;
  }
  .content h5 {
    font-size: 1.2rem;
  }
  .content h6 {
    font-size: 1rem;
  }
  .content h1,
  .content h2,
  .content h3,
  .content h4,
  .content h5,
  .content h6 {
    margin: 1.75rem 0;
    color: $mainBlue;
    font-weight: 900;
    line-height: 1.1;
  }

  .markdown-body .highlighter-rouge {
    width: calc(50px + 50vw);
    max-width: 500px;
  }

  .highlighter-rouge .highlight {
    margin-top: 5px;
  }

  .highlighter-rouge .highlight .highlight {
    overflow-x: auto;
  }

  .content pre {
    background: #00110d !important;
    padding: 12px 13px;
    margin-bottom: 1.75rem;
  }

  .content code {
    margin: 0 2px;
    padding: 3px 5px;
    color: #97e1ff;
    border-radius: 2px;
    white-space: pre;
    background-color: #00110d;
    font-family: monospace;
  }

  .content blockquote,
  .content blockquote p {
    margin-left: -1.75rem;
    padding-left: 1.42188rem;
    margin-bottom: 1.75rem;
    font-size: 1.20112rem;
    line-height: 1.75rem;
    color: hsla(0, 0%, 0%, 0.59);
    font-style: italic;
    border-left: 0.32813rem solid hsla(0, 0%, 0%, 0.9);
  }

  .content strong {
    font-weight: bold;
  }

  .content ul,
  .content ol {
    margin-left: 1rem;
    margin-bottom: 10px;
    font-size: 1.05em;
    line-height: 1.7em;
  }

  .content table {
    width: 100%;
    margin: 1rem 0;
    border-collapse: collapse;
  }

  .content table th {
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
  }
  .content table * {
    border: 1px solid rgba(0, 0, 0, 0.9);
    padding: 0.2rem;
  }

  .content img {
    max-width: 100%;
    margin: 1rem 0;
  }

  /* syntax */

  .highlight .hll {
    background-color: #333333;
  }
  .highlight {
    color: #ffffff;
  }
  .highlight .c {
    color: #008800;
    font-style: italic;
    background-color: #0f140f;
  } /* Comment */
  .highlight .err {
    color: #ffffff;
  } /* Error */
  .highlight .g {
    color: #ffffff;
  } /* Generic */
  .highlight .k {
    color: #fb660a;
    font-weight: bold;
  } /* Keyword */
  .highlight .l {
    color: #ffffff;
  } /* Literal */
  .highlight .n {
    color: #ffffff;
  } /* Name */
  .highlight .o {
    color: #ffffff;
  } /* Operator */
  .highlight .x {
    color: #ffffff;
  } /* Other */
  .highlight .p {
    color: #ffffff;
  } /* Punctuation */
  .highlight .cm {
    color: #008800;
    font-style: italic;
    background-color: #0f140f;
  } /* Comment.Multiline */
  .highlight .cp {
    color: #ff0007;
    font-weight: bold;
    font-style: italic;
    background-color: #0f140f;
  } /* Comment.Preproc */
  .highlight .c1 {
    color: #008800;
    font-style: italic;
    background-color: #0f140f;
  } /* Comment.Single */
  .highlight .cs {
    color: #008800;
    font-style: italic;
    background-color: #0f140f;
  } /* Comment.Special */
  .highlight .gd {
    color: #ffffff;
  } /* Generic.Deleted */
  .highlight .ge {
    color: #ffffff;
  } /* Generic.Emph */
  .highlight .gr {
    color: #ffffff;
  } /* Generic.Error */
  .highlight .gh {
    color: #ffffff;
    font-weight: bold;
  } /* Generic.Heading */
  .highlight .gi {
    color: #ffffff;
  } /* Generic.Inserted */
  .highlight .go {
    color: #444444;
    background-color: #222222;
  } /* Generic.Output */
  .highlight .gp {
    color: #ffffff;
  } /* Generic.Prompt */
  .highlight .gs {
    color: #ffffff;
  } /* Generic.Strong */
  .highlight .gu {
    color: #ffffff;
    font-weight: bold;
  } /* Generic.Subheading */
  .highlight .gt {
    color: #ffffff;
  } /* Generic.Traceback */
  .highlight .kc {
    color: #fb660a;
    font-weight: bold;
  } /* Keyword.Constant */
  .highlight .kd {
    color: #fb660a;
    font-weight: bold;
  } /* Keyword.Declaration */
  .highlight .kn {
    color: #fb660a;
    font-weight: bold;
  } /* Keyword.Namespace */
  .highlight .kp {
    color: #fb660a;
  } /* Keyword.Pseudo */
  .highlight .kr {
    color: #fb660a;
    font-weight: bold;
  } /* Keyword.Reserved */
  .highlight .kt {
    color: #cdcaa9;
    font-weight: bold;
  } /* Keyword.Type */
  .highlight .ld {
    color: #ffffff;
  } /* Literal.Date */
  .highlight .m {
    color: #0086f7;
    font-weight: bold;
  } /* Literal.Number */
  .highlight .s {
    color: #0086d2;
  } /* Literal.String */
  .highlight .na {
    color: #ff0086;
    font-weight: bold;
  } /* Name.Attribute */
  .highlight .nb {
    color: #ffffff;
  } /* Name.Builtin */
  .highlight .nc {
    color: #ffffff;
  } /* Name.Class */
  .highlight .no {
    color: #0086d2;
  } /* Name.Constant */
  .highlight .nd {
    color: #ffffff;
  } /* Name.Decorator */
  .highlight .ni {
    color: #ffffff;
  } /* Name.Entity */
  .highlight .ne {
    color: #ffffff;
  } /* Name.Exception */
  .highlight .nf {
    color: #ff0086;
    font-weight: bold;
  } /* Name.Function */
  .highlight .nl {
    color: #ffffff;
  } /* Name.Label */
  .highlight .nn {
    color: #ffffff;
  } /* Name.Namespace */
  .highlight .nx {
    color: #ffffff;
  } /* Name.Other */
  .highlight .py {
    color: #ffffff;
  } /* Name.Property */
  .highlight .nt {
    color: #fb660a;
    font-weight: bold;
  } /* Name.Tag */
  .highlight .nv {
    color: #fb660a;
  } /* Name.Variable */
  .highlight .ow {
    color: #ffffff;
  } /* Operator.Word */
  .highlight .w {
    color: #888888;
  } /* Text.Whitespace */
  .highlight .mf {
    color: #0086f7;
    font-weight: bold;
  } /* Literal.Number.Float */
  .highlight .mh {
    color: #0086f7;
    font-weight: bold;
  } /* Literal.Number.Hex */
  .highlight .mi {
    color: #0086f7;
    font-weight: bold;
  } /* Literal.Number.Integer */
  .highlight .mo {
    color: #0086f7;
    font-weight: bold;
  } /* Literal.Number.Oct */
  .highlight .sb {
    color: #0086d2;
  } /* Literal.String.Backtick */
  .highlight .sc {
    color: #0086d2;
  } /* Literal.String.Char */
  .highlight .sd {
    color: #0086d2;
  } /* Literal.String.Doc */
  .highlight .s2 {
    color: #0086d2;
  } /* Literal.String.Double */
  .highlight .se {
    color: #0086d2;
  } /* Literal.String.Escape */
  .highlight .sh {
    color: #0086d2;
  } /* Literal.String.Heredoc */
  .highlight .si {
    color: #0086d2;
  } /* Literal.String.Interpol */
  .highlight .sx {
    color: #0086d2;
  } /* Literal.String.Other */
  .highlight .sr {
    color: #0086d2;
  } /* Literal.String.Regex */
  .highlight .s1 {
    color: #0086d2;
  } /* Literal.String.Single */
  .highlight .ss {
    color: #0086d2;
  } /* Literal.String.Symbol */
  .highlight .bp {
    color: #ffffff;
  } /* Name.Builtin.Pseudo */
  .highlight .vc {
    color: #fb660a;
  } /* Name.Variable.Class */
  .highlight .vg {
    color: #fb660a;
  } /* Name.Variable.Global */
  .highlight .vi {
    color: #fb660a;
  } /* Name.Variable.Instance */
  .highlight .il {
    color: #0086f7;
    font-weight: bold;
  } /* Literal.Number.Integer.Long */

  /* dark mode */

  .darkmode {
    color: white !important;
    background-color: black !important;
  }
  .darkmode header p,
  .darkmode header h1 {
    color: white !important;
  }

  .darkmode p {
    color: white !important;
  }

  .darkmode .dmode img {
    -webkit-animation: spin 0.4s linear;
    -moz-animation: spin 0.4s linear;
    animation: spin 0.4s linear;
  }

  html,
  body {
    transition-duration: 0.4s;
  }

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(45deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(45deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  /* Emoji */

  .emoji {
    margin-bottom: -4px;
  }

  /* light box */

  /* lightbox */

  .content img {
    cursor: zoom-in;
  }

  #lightbox {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: none;
  }

  #lightbox.active {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #lightbox img {
    max-width: 90%;
    max-height: 80%;
    padding: 4px;
    background-color: black;
    border: 2px solid white;
  }
}
//Home Blog Page that shows all the cards
section.blog-card-css {
  margin-left: auto;
  margin-right: auto;
  max-width: 1080px;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;

  .card {
    background-color: white;
    height: 500px;
    overflow: hidden;
    flex-basis: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin: 10px;
    border-radius: 3px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    &__cover-image {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
      max-height: 200px;
      img {
        border-radius: 0px;
      }
    }

    img {
      flex-shrink: 0;
      width: 100%;
    }
  }
  .card:nth-child(1) {
    flex-basis: 520px;
    .card__cover-image {
      height: 300px;
      img {
        border-radius: 0px;
      }
    }
  }
  .link-to-post {
    transition: 0.2s;
    transform: translateY(0);
    &:hover {
      color: #699df7;
      transform: translateY(-2px);
    }
    .title {
      padding: 15px 15px 0 15px;
    }
  }
  .post {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    .date {
      padding: 10px 0 15px 0;
      margin: 0 15px;
      padding-bottom: 10px;
      font-size: 100%;
      display: block;
      position: relative;
      &::after {
        content: "";
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        display: block;
        background-color: rgb(211, 211, 211);
      }
    }
    .excerpt {
      padding: 15px;
      padding-bottom: 20px;
      flex-grow: 1;
    }
  }

  .card-footer {
    width: 100%;
    padding: 0 15px;
    position: absolute;
    bottom: 0;
    padding-bottom: 10px;
    .post-category {
      display: inline-block;
      border-radius: 3px;
      padding: 5px;
      margin: 3px 0;
      color: $categoryTextColor;
    }
  }

  .post-noimg {
    display: flex;
    height: 500px;
    flex-direction: column;
    align-items: stretch;
    position: relative;

    .date {
      padding: 10px 0 15px 0;
      margin: 0 15px;
      padding-bottom: 10px;
      font-size: 100%;
      display: block;
      position: relative;
      &::after {
        content: "";
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        display: block;
        background-color: rgb(211, 211, 211);
      }
    }
    .excerpt {
      padding: 15px;
      flex-grow: 2;
      padding-bottom: 20px;
    }
    .title {
      padding: 30px 20% 30px 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-image: url("/assets/img/blog/double-bubble-outline.png");
      background-repeat: repeat;
      background-size: auto;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      min-height: 250px;
      color: $mainBlue;
      transition: 0.2s;
      transform: translateY(0);
      box-shadow: 0 0px 20px rgba(0, 0, 0, 0.1);
      &:hover {
        color: #699df7;
        transform: translateY(-2px);
      }
    }
  }
}

// Custome CSS for Blog
.blog-header-img {
  background: url("/assets/img/mixer-background-2.jpg");
  min-height: auto;
  background-position: center top;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img.logo {
    max-width: 50vw;
    min-width: 800px;
  }
}
.blog-nav-to-blog-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: $mainMargin;
}
#blog-nav-to-blog {
  background-color: $mainYellow;
  margin-bottom: $mainMargin;
  color: $mainBlack;
  border-radius: 5px;
  padding: 10px;
  font-family: $primaryFont;
  font-size: 1.2rem;
  display: block;
  text-decoration: none;
  border-bottom: none;
  font-weight: 700;
  text-align: center;
  transition: 0.2s;
  &:hover {
    background-color: $mainBlack;
    color: white;
    transition: 0.1s;
  }
}

//Back to Top Button
#back-to-top-btn {
  position: fixed;
  z-index: 999;
  opacity: 0;
  display: block;
  transform: translateX(50%) translateY(50%);
  right: 400px;
  bottom: 120px;
  font-family: "Roboto", sans-serif;
  padding: 15px;
  font-size: 25px;
  border-radius: 5px;
  border: 3px solid rgb(58, 58, 58);
  cursor: pointer;
  background-color: #ffdf64;
  color: rgb(54, 54, 54);
  transition: color, 0.2s;
  font-weight: 700;
  &:hover {
    transition: color, 0.2s;
    color: rgb(236, 236, 236);
    background-color: rgb(36, 36, 36);
    border: 3px solid rgb(36, 36, 36);
  }
}
.btnEntrance {
  animation-duration: 0.25s;
  animation-fill-mode: both;
  animation-name: btnEntrance;
}
@keyframes btnEntrance {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}
.btnExit {
  animation-duration: 0.25s;
  animation-fill-mode: both;
  animation-name: btnExit;
}
@keyframes btnExit {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

//For Youtube Embedding Responsiveness
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

//Embed from Mailchimp

@import "mailchimp.scss";

.category-default-color {
  background-color: #b4b4b4 !important;
}
.category-color-1 {
  background-color: rgb(145, 174, 255) !important;
}
.category-color-2 {
  background-color: rgb(240, 195, 152) !important;
}
.category-color-3 {
  background-color: rgb(177, 233, 144) !important;
}
.category-color-4 {
  background-color: rgb(250, 146, 146) !important;
}
.category-color-5 {
  background-color: rgb(236, 221, 138) !important;
}
.category-color-6 {
  background-color: rgb(241, 159, 241) !important;
}
.category-color-7 {
  background-color: rgb(144, 221, 226) !important;
}

// --- Tablet View
@media (min-width: 731px) and (max-width: 1024px) {
  .blog-header-img img.logo {
    max-width: 50vw;
    min-width: auto;
  }
  section.blog-card-css {
    max-width: 540px;
  }
  #mc_embed_signup input.button {
    margin: 10px auto;
  }
}

// ---- Mobile
@media only screen and (max-width: 730px) {
  .blog-header-img img.logo {
    max-width: 50vw;
    min-width: auto;
  }
  section.blog-card-css {
    .card {
      flex-basis: 100%;
      height: 400px;
    }
    .card:nth-child(1) {
      flex-basis: 100%;
    }
    .card__cover-image {
      max-height: 150px;
    }
  }
}
