$primaryFont: "Montserrat", sans-serif;
$secondaryFont: "Roboto", sans-serif;
$linkColor: #25a7e4;
$linkHoverColor: #42cbea;
$mainBlue: #4980e2;
$mainBlack: #303030;
$mainLightBlue: rgb(93, 146, 238);
$mainMargin: 50px;
$mainYellow: #ffdf64;
$mainTextColor: #333333;

// ---- HOME --------
$headerBlack: #202020;
$bodyBlack: #2d2d2d;


// --- NavBar ---
$navColor: rgb(32, 32, 32);
$navTextColor: #fff;
$navHoverColor: #ffdf64;
$burgerColor: white;
$mainPurple: #8536eb;

// ----- BLOG --------
$categoryTextColor: rgb(36, 36, 36);

// -------- PRICING ---------
$buttonColor: #8536eb;
$backgroundBlack: #202020;
