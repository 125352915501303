@import 'variables';

//Mixins
@mixin center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

// ---- Body CSS ----
h1,
h2,
h3,
h4 {
  font-family: $primaryFont;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $secondaryFont;
}


html {
  font-size: 16px;
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
  cursor: pointer;
  color: $linkColor;
  &:hover {
    color: $linkHoverColor;
    transition: 0.1s;
  }
}
h3 {
  font-size: 24px;
  font-weight: 600;
  color: #609cf4;
  margin-top: 0px;
}
a:hover + h2 {
  color: #42cbea;
  animation-duration: 0.2s;
  transition: 0.2s;
}
a + h2 {
  animation-duration: 0.2s;
  transition: 0.2s;
  color: #4980e2;
}
a:hover + h3 {
  color: #42cbea;
  animation-duration: 0.2s;
  transition: 0.2s;
}
a + h3 {
  animation-duration: 0.2s;
  transition: 0.2s;
  color: #4980e2;
}
span {
  font-style: italic;
  font-size: inherit;
}
b {
  font-weight: 700;
  color: inherit;
  font-size: inherit;
}
img {
  max-width: inherit;
}
mark {
  background-color: rgb(255, 249, 162);
  border-radius: 5px;
  font-family: inherit;
  color: inherit;
  font-size: inherit;
}
.dir-column {
  flex-direction: column;
}
//shadow
.shadow {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}
//shadow
.shadow-light {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}

// --- Mobile ---
@media only screen and (max-width: 730px) {
  html {
    font-size: 13px;
  }
}
