/* MailChimp Form Embed Code - 12/15/2015 v10.7 */
#mc_embed_signup form {
  display: block;
  position: relative;
  text-align: left;
  padding: 10px 0 10px 3%;
}

#mc_embed_signup h2 {
  font-weight: bold;
  padding: 0;
  margin: 15px 0;
  font-size: 1.4em;
}
#mc_embed_signup input {
  border: 1px solid #999;
  -webkit-appearance: none;
}
#mc_embed_signup input[type="checkbox"] {
  -webkit-appearance: checkbox;
}
#mc_embed_signup input[type="radio"] {
  -webkit-appearance: radio;
}
#mc_embed_signup input:focus {
  border-color: #333;
}
#mc_embed_signup .button {
  clear: both;
  background-color: #aaa;
  border: 0 none;
  border-radius: 4px;
  letter-spacing: 0.03em;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  height: 32px;
  line-height: 32px;
  margin: 0 5px 10px 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  white-space: nowrap;
  width: auto;
  transition: all 0.23s ease-in-out 0s;
}
#mc_embed_signup .button:hover {
  background-color: rgb(48, 48, 48);
  color: $mainYellow;
}
#mc_embed_signup .small-meta {
  font-size: 11px;
}
#mc_embed_signup .nowrap {
  white-space: nowrap;
}
#mc_embed_signup .clear {
  clear: none;
  display: inline;
}

#mc_embed_signup label {
  display: block;
  font-size: 16px;
  padding-bottom: 10px;
  font-weight: bold;
}
#mc_embed_signup input.email {
  font-family: "Open Sans", "Helvetica Neue", Arial, Helvetica, Verdana,
    sans-serif;
  font-size: 15px;
  display: block;
  padding: 0 0.4em;
  margin: 0 4% 10px 0;
  min-height: 32px;
  width: 58%;
  min-width: 130px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
#mc_embed_signup input.button {
  display: block;
  width: 300px;
  margin: 0 0 10px 0;
  min-width: 90px;
}

#mc_embed_signup div#mce-responses {
  float: left;
  top: -1.4em;
  padding: 0em 0.5em 0em 0.5em;
  overflow: hidden;
  width: 90%;
  margin: 0 5%;
  clear: both;
}
#mc_embed_signup div.response {
  margin: 1em 0;
  padding: 1em 0.5em 0.5em 0;
  font-weight: bold;
  float: left;
  top: -1.5em;
  z-index: 1;
  width: 80%;
}
#mc_embed_signup #mce-error-response {
  display: none;
}
#mc_embed_signup #mce-success-response {
  color: #529214;
  display: none;
}
#mc_embed_signup label.error {
  display: block;
  float: none;
  width: auto;
  margin-left: 1.05em;
  text-align: left;
  padding: 0.5em 0;
}

#mc_embed_signup {
  background: #fff;
  clear: left;
  font: 14px Helvetica, Arial, sans-serif;
}
#mc_embed_signup form {
  display: block;
  position: relative;
  text-align: left;
  padding: 0;
  h3,
  h4,
  h2,
  h6 {
    color: $mainBlack;
  }
}
hr.space {
  margin: 50px 0;
}
#mc_embed_signup .button {
  margin: auto;
}
#mc_embed_signup input.button {
  margin: auto;
}
#mc_embed_signup input.email {
  margin: 10px auto;
  width: 500px;
}
#mc_embed_signup {
  background: rgb(255, 255, 255);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  clear: left;
  text-align: center;
  width: 100%;
  margin: 0 auto 50px auto;
  padding: 40px 0;
}
#mc_embed_signup form {
  text-align: center;
}

@media (min-width: 731px) and (max-width: 1024px) {
  #mc_embed_signup input.email {
    margin: auto;
    width: 70%;
  }
}

@media only screen and (max-width: 730px) {
  #mc_embed_signup input.email {
    margin: auto;
    width: 80%;
  }
  #mc_embed_signup input.button {
    margin: 10px auto;
    width: 80%;
  }
}
