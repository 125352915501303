img {
  border-radius: 3px;
}
.team-card {
  @include center-flex;
  flex-direction: column;
  h2{
    font-weight:600;
  }
}
.flex_not_2_columns {
  display: flex;
  flex-wrap: nowrap;
  align-items: start;
  margin: 0px;
  padding: 0px;
  margin: 30px auto;
}
.column {
  position: relative;
  width: 50%;
}
.textcenter {
  text-align: center;
}
.flex_not_2_columns img {
  margin-bottom: 20px;
}
.img_caption {
  font-size: 20px;
  color: $mainTextColor;
}
.small_column {
  width: 360px;
  align-self: start;
}
.small_column + .column {
  align-self: center;
  margin-top: -10px;
}
.borderhover:hover {
  animation-duration: 0.2s;
  transition: 0.2s;
  box-shadow: 0px 0px 0px 5px #42cbea;
}
.borderhover {
  margin: 15px;
}
.width340 {
  width: 340px;
}
.width360 {
  width: 360px;
}
.margin10 {
  margin: 10px;
}
.width255 {
  width: 510px;
}

//new code
// Team Member
.member-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1000px;
  margin: auto;
  padding-top: $mainMargin * 2;
  img {
    width: 40%;
  }
  h1 {
    color: $mainBlue;
    font-size: 2rem;
    text-align: center;
  }
  h2 {
    color: $mainTextColor;
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
    margin: 5px 0 50px 0;
    font-family: $secondaryFont;
  }
  p {
    font-size: 1.4rem;
    color: $mainTextColor;
  }
  .member-bio {
    flex-basis: 60%;
    padding: 0 0 0 80px;
  }
}
.member-format-2 {
  padding-top: 0;
  margin-top: $mainMargin/2;
}
.member-titles {
  padding-top: $mainMargin;
  h1 {
    color: $mainBlue;
    font-size: 2rem;
    text-align: center;
  }
  h2 {
    color: $mainTextColor;
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
    margin: 0;
    padding: 0;
    font-family: $secondaryFont;
  }
}

/* Mobile Landscape */
@media (min-width: 731px) and (max-width: 1100px) {
  /*Typical body CSS*/
  .brush2 {
    background: url("https://shelbyrowproductions.com/wp-content/uploads/2019/09/brush2-12.svg");
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .main_body_content {
    margin: 0px auto;
    padding-bottom: 0;
    padding-top: 30px;
    width: 90%;
  }
  .flex {
    display: flex;
    flex-wrap: nowrap;
    align-items: start;
    margin: 30px auto;
  }
  .flex_not_2_columns {
    display: flex;
    flex-wrap: nowrap;
    align-items: start;
    margin: 0px;
    padding: 0px;
    margin: 30px auto;
  }
  .column {
    position: relative;
    width: 50%;
    overflow: hidden;
  }
  .flex .column:first-child {
    margin-right: 80px;
  }
  .flex .small_column:first-child {
    margin-right: 30px;
  }
  .flex_not_2_columns .column {
    padding: 0px;
  }
  .textcenter {
    text-align: center;
  }
  .flex_not_2_columns img {
    margin-bottom: 20px;
  }
  .img_caption {
    font-size: 1.3rem;
  }
  .small_column {
    width: 360px;
    align-self: start;
  }
  .small_column + .column {
    align-self: center;
    margin-top: -10px;
  }
  .borderhover:hover {
    animation-duration: 0.2s;
    transition: 0.2s;
    box-shadow: 0px 0px 0px 5px #42cbea;
  }
  .borderhover {
    margin: 15px;
  }
  .fade_img_overlay:hover {
    transition: 0.3s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
  }
  .fade_img_overlay {
    transition: 0.3s ease;
    opacity: 0.2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    width: 100%;
    height: 100%;
  }
  .width340 {
    width: 220px;
  }
  .width360 {
    width: 360px;
  }
  .margin10 {
    margin: 10px;
  }
  .width255 {
    width: 510px;
  }
  .member-flex {
    align-items: center;
    width: 95vw;
    margin: auto;
    padding-top: $mainMargin;
    h1 {
      color: $mainBlue;
      font-size: 2rem;
      text-align: center;
    }
    h2 {
      color: $mainTextColor;
      font-weight: 500;
      font-size: 1.2rem;
      text-align: center;
      margin: 5px 0 30px 0;
      font-family: $secondaryFont;
    }
    p {
      font-size: 1.5rem;
      color: $mainTextColor;
    }
    .member-bio {
      padding: 0 10px 0 40px;
    }
  }
}

/*MOBILE*/
@media only screen and (max-width: 730px) {
  /*Typical body CSS*/
  .main_body_content {
    margin: 0px auto;
    width: auto;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 0;
    padding-top: 30px;
  }
  .flex {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    margin: 30px auto;
  }
  .flex_not_2_columns {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    margin: 0px;
    padding: 0px;
    margin: 30px auto;
  }
  .column {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .flex .column:first-child {
    margin-right: 0px;
  }
  .flex .small_column:first-child {
    margin-right: auto;
  }
  .flex_not_2_columns .column {
    padding: 0px;
    width: auto;
    margin: auto;
  }
  .textcenter {
    text-align: center;
  }
  .flex_not_2_columns img {
    margin-bottom: 5px;
  }
  .img_caption {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .small_column {
    width: 360px;
    margin: auto;
  }
  .small_column + .column {
    align-self: center;
    margin-top: -10px;
  }
  .scaledown {
    zoom: 0.5;
    transform: scale(0.5);
  }
  .borderhover:hover {
    animation-duration: 0.2s;
    transition: 0.2s;
    box-shadow: 0px 0px 0px 5px #42cbea;
  }
  .borderhover {
    margin: 15px;
  }
  .width340 {
    width: 340px;
  }
  .width360 {
    width: 360px;
    margin-bottom: 20px;
  }
  .margin10 {
    margin: 10px;
  }
  .width255 {
    width: 510px;
  }
  .member-flex {
    flex-wrap: wrap;
    width: 95%;
    padding-top: $mainMargin;
    img {
      width: 100%;
    }
    h1 {
      color: $mainBlue;
      font-size: 2rem;
      text-align: center;
    }
    h2 {
      color: $mainTextColor;
      font-weight: 500;
      font-size: 1.2rem;
      text-align: center;
      margin: 5px 0 30px 0;
      font-family: $secondaryFont;
    }
    p {
      font-size: 1.5rem;
      color: $mainTextColor;
    }
    .member-bio {
      padding: 5px;
      flex-basis: 100%;
    }
  }
  .member-format-2 {
    padding-top: 0;
    margin-top: $mainMargin/4;
    p {
      margin-top: 20px;
    }
  }
  .member-titles {
    padding-top: $mainMargin;
  }
}
