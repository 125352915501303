.pricing-packages {
  display: flex;
  flex-wrap: nowrap;
  margin: auto;
  margin-top: $mainMargin;
  align-items: stretch;
  width: 1100px;
  justify-content: space-around;
  .shift-up {
    transform: translateY(-40px);
    //This below is a copy of h2.package-title, but adjusted for the middle column. Keep most values the same.
    h2.middle-package-title {
      text-align: center;
      margin-bottom: 0;
      color: $mainYellow;
      padding: 30px 0;
      background-color: $backgroundBlack;
      text-transform: uppercase;
      letter-spacing: 2px;
      border-radius: 12px 12px 0 0;
      font-size: 2.4em;
    }
  }

  .pkg {
    background-color: white;
    border-radius: 12px;
    padding: 0px;
    min-height: 400px;
    position: relative;
    flex-basis: 30%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    h2.package-title {
      text-align: center;
      margin-bottom: 0;
      color: white;
      padding: 30px 0;
      border-radius: 12px 12px 0 0;
      background-color: $mainBlue;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 2.4em;
    }
    .price {
      padding: 50px 0;
      h3.price-of-package {
        text-align: center;
        font-size: 2.9em;
        color: #202020;
        line-height: 0.7em;
        span {
          font-size: 0.4em;
          font-style: normal;
        }
      }
    }
    p {
      font-size: 21px;
      color: $mainTextColor;
      margin-bottom: 20px;
    }
    ul.services-in-pricing {
      line-height: 1.3em;
      font-size: 1.1em;
      display: block;
      color: #1c7ea5;
      width: 86%;
      margin: auto;
      list-style-type: none;
      li:not(:last-child) {
        border-bottom: 1.5px solid rgb(235, 235, 235);
      }
      li {
        padding: 10px 0;
        .fa-question-circle {
          color: rgb(106, 139, 150);
        }
      }
      li.inactive {
        color: #d3d3d3;
      }
    }
    p.get-started {
      width: 80%;
      display: block;
      margin: 30px auto;
      background-color: rgb(236, 236, 236);
      border-radius: 5px;
      font-size: 1.2em;
      padding: 10px;
      text-align: center;
      color: rgb(105, 105, 105);
    }
    a.get-started {
      display: block;
      text-align: center;
      font-family: $primaryFont;
      font-size: 1em;
      font-weight: 700;
      border-radius: 15px;
      width: 200px;
      background-color: $buttonColor;
      padding: 15px 5px;
      margin: 30px auto;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: white;
      transition: 0.1s;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0);
      &:hover {
        background-color: $mainBlue;
        transition: 0.1s;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
      }
    }
    i.fa-star {
      font-size: 0.6em;
    }
    .most-popular {
      position: absolute;
      left: 10%;
      top: -25px;
      width: 80%;
      border-radius: 10px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      z-index: 999;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
      padding: 10px;
      background-color: rgb(51, 51, 51);
      color: $mainYellow;
      font-size: 1.3em;
    }
  }
}

div.small-note {
  font-size: 1.1em;
  width: 90%;
  color: $mainTextColor;
  margin: 50px auto 0 auto;
  font-style: italic;
  text-align: center;
}

.pricing-three-columns {
  width: 1000px;
  z-index: -1;
  .pkg {
    flex-basis: 32%;
  }
}
.pricing-disclaimer-wrapper {
  display: flex;
  justify-content: center;
  .pricing-disclaimer {
    padding-top: 15px;
    display: inline-block;
    width: auto;
    margin: auto;
    text-align: left;

    p {
      font-size: 1.1em;
      font-style: italic;
      line-height: 1.3em;
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  font-size: 0.9em;
  line-height: 1.3em;
  margin-left: 5px;
  .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: rgb(66, 66, 66);
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 9999;
    top: 200%;
    left: 50%;
    margin-left: -100px;
    ul.tooltip-list {
      list-style-type: none;
      font-size: 0.9em;
      font-family: $secondaryFont;
      padding: 0 10px;
      letter-spacing: 0px;
      font-weight: normal;
      li:not(:last-child) {
        border-bottom: 1.5px solid rgb(112, 112, 112);
      }
    }
  }
  .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgb(66, 66, 66) transparent;
  }
  &:hover .tooltiptext {
    visibility: visible;
  }
}
.parent-pricing-quote-and-services {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pricing-quote-and-services {
  display: inline-block;
  width: auto;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  margin: $mainMargin auto 0 auto;
  text-align: left;
  line-height: 1.5em;
  font-size: 1.3em;
  padding: $mainMargin/1.5;
  h3 {
    margin-top: 0;
    color: $mainBlack;
    font-size: 1.7em;
    font-weight: 800;
  }
  .button-flex-two {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.quote-button {
  display: block;
  text-align: center;
  font-family: $primaryFont;
  font-size: 1em;
  font-weight: 700;
  border-radius: 14px;
  background-color: $mainPurple;
  padding: 15px 25px;
  margin-right: 40px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
  transition: 0.2s;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0);
  &:hover {
    background-color: $mainBlue;
    color: white;
    transition: 0.2s;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
}

.see-more-services {
  text-align: center;
}

.black-simple-titles {
  width: 800px;
  text-align: center;
  margin: $mainMargin * 1.5 auto 0 auto;
  h2 {
    font-size: 2.8em;
    color: $mainTextColor;
  }
  h3 {
    margin-top: $mainMargin/2;
    font-size: 2em;
    color: $mainTextColor;
  }
}

.standalone-pkg {
  background-color: white;
  margin: $mainMargin * 1.5 auto 0 auto;
  border-radius: 12px;
  padding: 0px;
  min-height: 400px;
  z-index: 99;
  position: relative;
  width: 700px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  h2.package-title {
    text-align: center;
    margin-bottom: 0;
    color: $mainYellow;
    padding: 30px 20px;
    border-radius: 12px 12px 0 0;
    background-color: $backgroundBlack;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 2.1em;
  }
  .price {
    padding: 50px 0;
    h3.price-of-package {
      text-align: center;
      font-size: 2.9em;
      color: #202020;
      line-height: 0.7em;
      span {
        font-size: 0.4em;
        font-style: normal;
      }
    }
  }
  p {
    font-size: 21px;
    color: $mainTextColor;
    margin-bottom: 20px;
  }
  ul.services-in-pricing {
    line-height: 1.3em;
    font-size: 1.1em;
    display: block;
    width: 80%;
    margin: auto;
    list-style-type: none;
    li:not(:last-child) {
      border-bottom: 1.5px solid rgb(235, 235, 235);
    }
    li {
      padding: 10px 0;

      .fa-question-circle {
        color: rgb(106, 139, 150);
      }
    }
    li.inactive {
      color: #d3d3d3;
    }
  }

  a.get-started {
    display: block;
    text-align: center;
    font-family: $primaryFont;
    font-size: 1em;
    font-weight: 700;
    border-radius: 15px;
    width: 200px;
    background-color: $buttonColor;
    padding: 15px 5px;
    margin: 30px auto;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: white;
    transition: 0.1s;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0);
    &:hover {
      background-color: $mainBlue;
      transition: 0.1s;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    }
  }
  .body {
    padding: 0 30px 1px 30px;
  }
}

.pricing-social-media-section {
  background: rgb(73, 128, 226);
  background: linear-gradient(
    0deg,
    rgba(73, 128, 226, 1) 0%,
    rgba(147, 210, 228, 1) 100%
  );
  //border-radius: 50% 50% 0 0 /50px;
  padding-top: 120px;
  padding-bottom: $mainMargin * 2;
  transform: translateY(-100px);
  z-index: -1;
  margin-bottom: -150px;
}

.pricing-setup-podcast {
  z-index: 1;
  padding-top: $mainMargin/2;
  padding-bottom: $mainMargin * 2;
}

.container-scrollmore {
  position: relative;

  .keepscrolling {
    position: absolute;
    bottom: 40px;
    right: 12%;
    display: none;
  }
}

.pricing-gray {
  background-color: #f2f2f2;
  width: 100%;
  margin-top: 0px;
  padding-top: 50px;
  color: #333333;
}

.white-bubble-bottom {
  background-color: #f2f2f2;
  margin-bottom: -5px;
  padding-bottom: 0;
  margin-top: $mainMargin;
}

// --- Tablet View
@media (min-width: 731px) and (max-width: 1024px) {
  .pricing-packages {
    width: 90%;
    .shift-up {
      h2.middle-package-title {
        font-size: 2em;
      }
    }
    .pkg {
      flex-basis: 32%;
      h2.package-title {
        font-size: 2em;
      }
      a.get-started {
        width: 80%;
      }
      .price {
        padding: 30px 0;
      }
    }
  }
  .pricing-quote-and-services {
    width: 90%;
  }
  .black-simple-titles {
    width: 90%;
    margin: 40px auto 0 auto;
  }
  .container-scrollmore {
    .keepscrolling {
      width: 30%;
      right: 1%;
    }
  }
  .standalone-pkg {
    margin: 40px auto 0 auto;
    .price {
      padding: 30px 0;
    }
  }
  .service-gray {
    background-color: #f2f2f2;
    width: 100%;
    margin-top: 0px;
    padding: 30px 0;
  }
  .pricing-gray {
    overflow-x: hidden;
  }
}

// ---- Mobile
@media only screen and (max-width: 730px) {
  .pricing-disclaimer-wrapper {
    width: 90%;
    display: block;
    margin: auto;
  }
  .pricing-gray {
    padding-top: $mainMargin/2;
  }
  .pricing-packages {
    width: 90%;
    flex-wrap: wrap;
    margin-top: 0px;
    .shift-up {
      transform: translateY(0px);
      h2.middle-package-title {
        font-size: 2em;
        padding: 20px 0;
      }
    }
    .pkg {
      flex-basis: 100%;
      margin-top: 20px;
      margin-bottom: 0;
      h2.package-title {
        font-size: 2em;
        padding: 20px 0;
      }
      a.get-started {
        width: 80%;
      }
      .price {
        padding: 30px 0;
      }
      ul.services-in-pricing {
        font-size: 1.3em;
      }
    }
    .inactive {
      display: none;
    }
    .pkg {
      .most-popular {
        display: none;
      }
    }
  }
  .pricing-quote-and-services {
    width: 90%;
    text-align: center;
    margin: 50px auto 0 auto;
    font-size: 1.2em;
    padding: $mainMargin/2;
  }
  .black-simple-titles {
    width: 90%;
    margin: 40px auto 0 auto;
    h2 {
      font-size: 1.7em;
    }
    h3 {
      font-size: 1.4em;
    }
  }
  .container-scrollmore {
    .keepscrolling {
      width: 30%;
      right: 1%;
    }
  }
  .standalone-pkg {
    margin: 40px auto 0 auto;
    width: 90%;
    .package-description {
      font-size: 1.3em;
    }
    h2.package-title {
      font-size: 1.7em;
    }
    .price {
      padding: 30px 0;
      font-size: 0.8em;
    }
    .body {
      padding: 0 15px 1px 15px;
    }
  }
  .service-gray {
    margin-top: 1px;
  }
  .container-scrollmore {
    .keepscrolling {
      display: none;
    }
  }
}
