@import "our-team";
@import "blog";
@import "pricing";
@import "forms";
@import "home";

// --- HOME PAGE ---
.home_row_wide {
  display: flex;
  width: 100%;
  height: calc(100vh-9vh);
  position: relative;
  box-sizing: content-box;
  flex-wrap: nowrap;
  border-bottom: solid 30px $mainYellow;
}
.home_column {
  flex-basis: 50%;
}
.bluespec {
  position: relative;
  background-image: url("/assets/img/spec.svg");
  top: 0;
  left: 0;
  background-color: $mainBlue;
  display: block;
}
.biglogo {
  position: absolute;
  width: 60%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
}
.home-biglogo-gray {
  display: none;
}
.scrollicon {
  height: 28%;
  position: absolute;
  transform: translate(0, -100%);
  top: 100%;
  left: 93%;
}
.home_opening_bg {
  background-image: url("https://shelbyrowproductions.com/wp-content/uploads/2019/09/crossmark.svg");
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
  display: flex;
  align-items: center;
}
.home_opening {
  width: auto;
  margin: auto;
  padding: 0 10%;
  h1 {
    font-size: 2.2rem;
    font-weight: 700;
    letter-spacing: -1px;
    color: rgb(33, 75, 138);
  }
}
.brush {
  background: url("/assets/img/paintbrush.svg");
  min-height: auto;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 70px 0;
}
.main_body_content {
  margin: 0px auto;
  width: 1200px;
}
.home-tall-img {
  height: 510px;
}
.flex-row-has-2 {
  padding: 10px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  h1 {
    color: $mainBlue;
  }
  p {
    margin: 20px auto;
    font-size: 1.7rem;
    color: $mainTextColor;
  }
  .column:first-child {
    width: 45%;
    margin-right: 100px;
    display: block;
  }
  .column:nth-child(2) {
    min-width: 580px;
    max-width: 580px;
    display: block;
  }
  img {
    border-radius: 10px;
    overflow: hidden;
  }
}
.bluebar {
  background-color: #4980e2;
  height: 15px;
  width: 100%;
  margin: 40px auto;
  border-radius: 3px;
}

// --- Services ---
//Podcast Editing
.service-hero {
  width: 100%;
  font-family: Montserrat, "sans-serif";
  font-size: 1.8rem;
  color: white;
  background-color: $mainBlue;
  text-align: center;
  padding: 30px 0;
  font-weight: 600;
}
.showcase-one-sample {
  margin-top: $mainMargin;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin: auto;
  margin-top: $mainMargin;
  max-width: 1200px;
  h2 {
    font-size: 1.5rem;
    color: $mainTextColor;
  }
  img {
    width: 160px;
  }
}
.showcase-links {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;
  max-width: 900px;
  font-family: Roboto, sans-serif;
  margin: auto;
  margin-top: $mainMargin;
  a.cards {
    background-color: $mainBlue;
    color: white;
    border-radius: 5px;
    padding: 12px;
    flex-basis: 16%;
    text-align: center;
    font-size: 1.2rem;
    @include center-flex();
    transition: 0.2s;
    &:hover {
      transform: translateY(-3px);
      transition: 0.2s;
      background-color: $mainLightBlue;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }
  }
}
div.service-title {
  text-align: center;
  margin-top: $mainMargin;
  color: $mainTextColor;
  h1 {
    font-family: Montserrat, "sans-serif";
    font-weight: 600;
    color: $mainBlack;
    font-size: 30px;
  }
  h2 {
    font-family: $secondaryFont;
    font-size: 22px;
    display: inline-block;
    font-weight: 700;
    color: $mainBlue;
    padding: 6px 10px;
    border-bottom: solid 3px $mainYellow;
  }
  p {
    max-width: 40%;
    max-width: 800px;
    margin: auto;
    margin-top: 20px;
    font-size: 1.6rem;
  }
  .plain {
    color: $mainBlack;
    font-weight: 500;
    font-family: $primaryFont;
    border-bottom: none;
  }
}
.service-card-container {
  display: flex;
  margin: auto;
  margin-top: $mainMargin/4;
  flex-wrap: wrap;
  padding: 0;
  max-width: 1300px;
  align-items: center;
  justify-content: center;
  a.service-card {
    padding: 10px;
    background-color: $mainBlue;
    margin: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    transition: 0.2s;
    transform: translateY(0px);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0);
    img {
      width: 170px;
    }
    p {
      width: 100%;
      color: white;
      font-size: 18px;
      font-weight: 300;
      text-align: center;
      padding: 10px;
      margin: 0;
      b {
        color: white;
        font-weight: 500;
      }
    }
    &:hover {
      background-color: $mainLightBlue;
      transition: 0.2s;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
      transform: translateY(-3px);
    }
  }
}
.has-2 {
  a.service-card {
    flex-basis: 35%;
  }
  div.column{
    flex-basis: 35%;
  }
}
.has-3 {
  a.service-card {
    flex-basis: 27%;
  }
}
#service-header-v2 {
  font-family: $primaryFont;
  font-weight: 600;
  font-size: 2.1rem;
  margin-top: $mainMargin;
  width: 50%;
  position: relative;
  left: 26%;
  color: $mainBlue;
  letter-spacing: 0.5px;
}
#service-header-v2-more {
  font-family: $primaryFont;
  font-weight: 600;
  font-size: 2.5rem;
  margin-top: $mainMargin * 4;
  width: 50%;
  position: relative;
  left: 26%;
  color: $mainBlue;
  letter-spacing: 0.5px;
}
#service-header-v1 {
  font-family: $primaryFont;
  font-weight: 700;
  text-align: center;
  font-size: 1.8rem;
  margin-top: $mainMargin;
  color: $mainBlue;
  letter-spacing: 0.5px;
}
.service-another-separate {
  width: 820px;
  margin: auto;
  margin-top: $mainMargin * 2;
  margin-bottom: $mainMargin * 3;
  position: relative;
  border: 0px solid rgb(35, 144, 233);
  border-radius: 17px;
  box-shadow: 0 0 20px rgba(0,0,0,.3);
  padding: 30px;
  h2 {
    font-size: 2rem;
    color: $mainTextColor;
    margin-bottom: 20px;
  }
  p {
    color: $mainTextColor;
    font-weight: 400;
    font-size: 1.4rem;
  }
}
.audio-box {
  @include center-flex;
  width: 60%;
  margin: auto;
  flex-wrap: wrap;
  flex-direction: column;
  padding-bottom: 10px;
  audio {
    display: block;
    margin: 10px auto;
  }
  h3 {
    margin: 0 0 20px 0;
    color: $mainBlue;
  }
}

//Cover Art
.showcase-graphic-design {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1500px;
  margin: auto;
  flex-wrap: wrap;
  margin-top:0;
  .service-cover-art {
    display: block;
    width: 500px;
    border-radius: 10px;
    margin-top: $mainMargin;
  }
  .service-cover-art:nth-child(1) {
    margin-right: 40px;
  }
  .classic-card:nth-child(1) {
    margin-right: 40px;
  }
}
.showcase-graphic-design-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: $mainMargin;
  .service-card-art {
    display: block;
    width: 500px;
    border-radius: 10px;
    margin-top: 20px;
    margin-right: 0;
  }
  .service-card-art:nth-child(1) {
    margin-top: 0px;
  }
}
.stylescape-wrapper {
  flex-direction: column;
  width: 80%;
  margin: auto;
  margin-top: $mainMargin;
  .service-stylescape {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 40px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  }
  .service-stylescape:last-child {
    margin-bottom: 0;
  }
}

//Service Gray Section
.service-gray {
  background-color: #f2f2f2;
  width: 100%;
  margin-top: $mainMargin;
  padding: $mainMargin 0;
  color: $mainTextColor;
  h1.service-title {
    margin-top: 0;
  }
  h2 {
    text-align: center;
    font-size: 2rem;
    color: $mainBlue;
    max-width: 800px;
    margin: auto;
    font-weight:600;
  }
  p {
    text-align: center;
    margin: 10px auto;
    max-width: 800px;
    font-size: 1.4rem;
    font-weight: 400;
  }
  .horizontal-pkg {
    background-color: #fff;
    max-width: 800px;
    margin: 40px auto;
    padding: 15px 20px 5px 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
}
.text-left {
  h1,
  h2,
  h3,
  h4,
  p {
    text-align: left;
  }
}
/*
.line-anim {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 4px;
    bottom: 0;
    left: 0;
    background-color: $mainBlue;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  &:hover::before {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 4px;
    top: 0;
    right: 0;
    background-color: $mainBlue;
    transform-origin: top left;
    transition: transform 0.25s ease-out;
  }
  &:hover::after {
    transform: scaleX(1);
    transform-origin: top right;
  }
}
*/
h1.service-title {
  font-family: Montserrat, "sans-serif";
  font-weight: 600;
  color: $mainTextColor;
  line-height: 1.1em;
  font-size: 30px;
  margin-top: $mainMargin * 1.5;
  text-align: center;
  letter-spacing: -0.8px;
}
.service-packages {
  display: flex;
  margin: auto;
  margin-top: $mainMargin;
  align-items: stretch;
  justify-content: space-between;
  max-width: 1250px;
  .pkg {
    background-color: white;
    border-radius: 12px;
    padding: 35px;
    min-height: 400px;
    flex-basis: 31%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    h2 {
      text-align: center;
      margin-bottom: 25px;
      color: $mainBlue;
    }
    p {
      font-size: 21px;
      color: $mainTextColor;
      margin-bottom: 20px;
    }
  }
}
.fa-star {
  color: $mainYellow;
}

//iframe Audiogram
#audiogram-videos {
  width: 1400px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .iframe-wrapper {
    //1:1 Ratio set width and height
    $videoHeightWidth: 400px;
    margin: $mainMargin $mainMargin/2 0 $mainMargin/2;
    padding-top: 0px;
    //padding-bottom: 56.2%;
    width: $videoHeightWidth;
    height: $videoHeightWidth;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  .iframe-wrapper-4by5 {
    //1:1 Ratio set width and height
    $videoHeight: 1080px/2;
    $videoWidth: 864px/2;
    margin: $mainMargin $mainMargin/2 0 $mainMargin/2;
    padding-top: 0px;
    //padding-bottom: 56.2%;
    width: $videoWidth;
    height: $videoHeight;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
}
iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  border: none;
}

.classic-card {
  a {
    margin-top: 15px;
    font-size: 1.3rem;
    font-weight: 600;
    font-family: $primaryFont;
    display: block;
    text-align: center;
  }
  img.service-graphic-art {
    width: 612px;
    height: auto;
  }
}

//Comparison Chart
.comparison-chart {
  width: 1100px;
  margin: auto;
  p {
    font-size: 17px;
    font-weight: 400;
    font-style: italic;
    margin-top: $mainMargin;
    color: $mainTextColor;
    opacity: 0.8;
  }
}
table.podcast-comparison {
  font-size: 22px;
  margin-top: $mainMargin;
  padding: 10px;
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
  td,
  th {
    padding: 15px 0;
  }
  td:nth-child(1) {
    text-align: left;
    color: $mainTextColor;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 18px;
  }
  td:nth-child(2) {
    color: #b5ef59;
  }
  td:nth-child(3) {
    color: #a6dd47;
  }
  td:nth-child(4) {
    color: #82c91e;
  }
  tr {
    border-bottom: 1px solid rgb(214, 214, 214);
    text-align: center;
  }
  th.service-name {
    width: 50%;
  }
  th.first-tier {
    color: #7baae2;
  }
  th.second-tier {
    color: $mainBlue;
    width: 16%;
  }
  th.third-tier {
    color: $mainBlack;
    width: 16%;
  }
  th {
    font-family: Montserrat, sans-serif;
  }
}
.hero-text {
  p {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    color: #4980e2;
    font-weight: 600;
    font-size: 28px;
    line-height: 18px;
    margin: 60px auto;
  }
}

//Service Navigation Table
div.hero-p {
  text-align: center;
  line-height: 1.4em;
  font-size: 1.4em;
  width: 600px;
  font-family: Roboto, sans-serif;
  color: $mainTextColor;
  margin: auto;
  .icon-open {
    font-size: 1.3em;
    color: $mainBlue;
    margin: auto;
    margin-left: 5px;
  }
  .icon-closed {
    font-size: 1.3em;
    color: $mainBlue;
    margin: auto;
    margin-left: 5px;
    transform: translateY(-4px);
  }
}
.envelope-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1.8em;
  font-size: inherit;
  margin: auto;
}
.envelope-icon:hover .icon-open,
.envelope-icon .icon-closed {
  display: none;
}
.envelope-icon:hover .icon-closed {
  display: inline;
}
.cal-btn {
  background-color: rgb(133, 54, 235);
  display: block;
  color: white;
  transition: 0.2s;
  &:hover {
    color: white;
    background-color: $mainBlue;
    transition: 0.1s;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    transform: translateY(-3px);
  }
  padding: 20px;
  max-width: 500px;
  margin: 20px auto;
  border-radius: 100px;
}

.soundcloud-showcase{
  width:1000px;
  margin:auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: $mainMargin auto 0 auto;
  .sound-snippet{
    width:420px;
    height: 170px;
    margin-bottom:80px;
    h3{
      margin: 0 0 7px 3px;
      color: $mainBlack;
      width: 420px;
    }
  }
  iframe{
    height:120px;
    width: 420px;
    display:block;
    //width needs to be same as .sound-snippet
  }
}
.sound__footer{
  display: none !important;
}

//Old services
.service-flex .service-bio:first-child {
  margin-right: 90px;
  font-size: 1.4rem;
  color: $mainTextColor;
}
.borderbullet {
  font-size: 20px;
  font-family: roboto;
  line-height: 1.5em;
  color: #666666;
  align-self: center;
  border-radius: 6px;
  border: 4px solid #4980e2;
  padding: 13px 0px;
  padding-left: 40px;
  margin: 0px;
  height: 100%;
  transform: translateY(-8px);
  margin-top: 0px;
  flex: none;
  width: 340px;
  box-shadow: 6px 6px 1px rgba(0, 0, 0, 0.2);
}
.spacer {
  margin: 40px auto;
}
.yellowbarsmall {
  height: 10px;
  border-radius: 2px;
  background-color: $mainYellow;
  width: 100%;
}
.service-flex {
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.old-service-title {
  color: $mainBlue;
  font-size: 2rem;
  margin-bottom: $mainMargin/2;
}
.old-service {
  h3 {
    margin-bottom: 6px;
  }
  p {
    font-size: 1.4rem;
    color: $mainTextColor;
  }
}
p.asterisk {
  font-style: italic;
  font-size: 1.3rem;
  margin-top: $mainMargin/4;
}
.old-service-pkg {
  margin: 50px auto;
}

//ABOUT PAGE
.brush2 {
  background: url("https://shelbyrowproductions.com/wp-content/uploads/2019/09/brush2-12.svg");
  min-height: 80vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 50px;
}
.blue {
  color: $mainBlue;
}
.top-padding {
  padding-top: $mainMargin;
}
.about-row {
  padding: 0;
  margin: 0;
  align-items: flex-start;
  p {
    padding: 5px 0 0 0;
    margin: 0;
  }
}
.about-nashville-img {
  height: 650px;
  margin-top: 5px;
}
.yellow-bar {
  height: 20px;
  width: 100%;
  background-color: $mainYellow;
  border-radius: 5px;
  margin: 50px auto;
}
.page-title {
  padding-top: $mainMargin * 2;
}
/*
.testimonial {
  color: $mainTextColor;
  min-width: 600px;
  max-width: 40%;
  border-radius: 20px;
  margin: auto;
  margin-top: $mainMargin * 2;
  margin-bottom: $mainMargin * 2;
  background-color: white;
  padding: 20px;
  padding-top: 40px;
  &-person {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: $secondaryFont;
    position: relative;
  }
  .quote {
    font-size: 1.2rem;
  }
  .name {
    margin-top: $mainMargin / 2;
    font-family: $primaryFont;
    font-weight: 500;
    font-size: 1.5rem;
    color: $mainBlue;
  }
}
.slide-2 {
  display: none;
} */

//service swipper CSS
.swiper-container {
  border-radius: 20px;
  width: 800px;
  padding: 20px;
  padding-top: 40px;
  margin-top: $mainMargin * 2;
  margin-bottom: $mainMargin * 2;
  img.quote-svg {
    position: absolute;
    top: 20px;
    left: 5%;
    height: 20%;
    z-index: -100;
    opacity: 0.1;
  }
  .quote {
    font-size: 1.2rem;
  }
  .name {
    margin-top: $mainMargin / 2;
    font-family: $primaryFont;
    text-align: center;
    font-weight: 500;
    font-size: 1.5rem;
    color: $mainBlue;
  }
  --swiper-theme-color: #4980e2;
}
.swiper-wrapper {
  color: $mainTextColor;
  margin: auto;
  margin-top: $mainMargin;
  margin-bottom: $mainMargin/2;
}
.swiper-slide {
  padding: 0 60px;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev,
.swiper-button-prev {
  right: 10px;
  left: auto;
  padding: 0 30px;
}

// --- Tablet View
@media (min-width: 731px) and (max-width: 1024px) {
  //iFrame Audiogram
  #audiogram-videos {
    width: 95%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    .iframe-wrapper {
      margin: $mainMargin 0 0 0;
      padding: 0px;
      //padding-bottom: 56.2%;
      width: 43vw;
      height: 0px;
      padding-bottom: 43vw;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }
  }

  //transcriptions
  .classic-card {
    width: 100%;
    a {
      margin-top: 15px;
      font-size: 1.3rem;
      font-weight: 600;
      font-family: $primaryFont;
      display: block;
      text-align: center;
    }
    img.service-graphic-art {
      width: 100%;
      height: auto;
    }
  }

  html {
    font-size: 13px;
  }
  .home_opening {
    h1 {
      font-size: 1.9rem;
    }
  }
  .main_body_content {
    margin: 0px auto;
    width: 95%;
  }
  .flex-row-has-2 {
    padding: 10px 0;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    overflow: hidden;
    justify-content: space-between;
    h1 {
      color: $mainBlue;
    }
    p {
      margin: 20px auto;
      font-size: 1.6rem;
      color: $mainTextColor;
    }
    .column:first-child {
      min-width: 45vw;
      overflow: hidden;
      margin-right: 5%;
      display: block;
    }
    .column:nth-child(2) {
      min-width: 30vw;
      display: block;
    }
    img {
      border-radius: 5px;
    }
  }
  .bluebar {
    background-color: #4980e2;
    height: 15px;
    width: 100%;
    margin: 40px auto;
    border-radius: 3px;
  }
  .brush {
    padding: 40px 0;
  }
  .service-gray {
    padding: 30px 5%;
  }
  .scrollicon {
    display: none;
  }
  .no-display-tablet {
    display: none;
  }
  .about-row {
    flex-wrap: wrap;
    .column {
      flex-basis: 100%;
      padding: 0;
      margin: 0;
      &:nth-child(2) {
        min-width: 100%;
      }
    }
  }
  .yellow-bar {
    margin: 0;
  }
  #back-to-top-btn {
    right: 5%;
    bottom: 5%;
    transform: translateX(0) translateY(0);
  }
  .service-flex .service-bio:first-child {
    margin-right: 30px;
    font-size: 1.4rem;
    color: $mainTextColor;
  }
  .borderbullet {
    font-size: 1.3rem;
    font-family: roboto;
    line-height: 1.5em;
    color: #666666;
    align-self: center;
    border-radius: 6px;
    border: 4px solid #4980e2;
    padding: 10px 0px;
    padding-left: 30px;
    margin: 0px;
    height: 100%;
    transform: translateY(-8px);
    margin-top: 0px;
    flex: none;
    width: 260px;
    box-shadow: 6px 6px 1px rgba(0, 0, 0, 0.2);
  }
  .spacer {
    margin: 40px auto;
  }
  .yellowbarsmall {
    height: 10px;
    border-radius: 2px;
    background-color: $mainYellow;
    width: 100%;
  }
  .service-flex {
    display: flex;
    justify-content: center;
    align-items: stretch;
  }
  .old-service-title {
    color: $mainBlue;
    font-size: 2rem;
    margin-bottom: $mainMargin/2;
  }
  .old-service {
    h3 {
      margin-bottom: 6px;
    }
    p {
      font-size: 1.3rem;
      color: $mainTextColor;
    }
  }
  p.asterisk {
    font-style: italic;
    font-size: 1.3rem;
    margin-top: $mainMargin/4;
  }
  .old-service-pkg {
    margin: 30px auto;
  }
  #service-header-v2 {
    font-family: $primaryFont;
    font-weight: 700;
    position: static;
    text-align: center;
    font-size: 1.5rem;
    padding: 0;
    width: 95%;
    margin: auto;
    margin-top: $mainMargin;
    color: $mainTextColor;
    letter-spacing: 0.5px;
  }

  .service-another-separate {
    width: auto;
    margin-top: $mainMargin * 2;
    margin-bottom: $mainMargin * 2;
    border-radius: 0;
    padding: 20px;
    border: none;
    background-color: $mainBlue;
    h2 {
      font-size: 2rem;
      color: white;
    }
    mark {
      background-color: #4d95e7;
      border-radius: 0;
    }
    p {
      font-size: 0.8rem;
      color: white;
      font-size: 1.4rem;
    }
  }
  //Comparison Chart
  .comparison-chart {
    width: 95%;
    margin: auto;
    p {
      font-size: 15px;
      font-weight: 400;
      font-style: italic;
      margin-top: $mainMargin;
      color: $mainTextColor;
      opacity: 0.8;
    }
  }
  table.podcast-comparison {
    margin-top: $mainMargin * 2;
    padding: 0;
    border-collapse: collapse;
    table-layout: auto;
    width: 100%;
    td,
    th {
      padding: 12px 0;
      .fa-check {
        font-size: 18px;
      }
    }
    td:nth-child(1) {
      text-align: left;
      color: $mainTextColor;
      font-family: Roboto, sans-serif;
      font-weight: 500;
      font-size: 16px;
    }
    td:nth-child(2) {
      color: #b5ef59;
    }
    td:nth-child(3) {
      color: #a6dd47;
    }
    td:nth-child(4) {
      color: #82c91e;
    }
    tr {
      border-bottom: 1px solid rgb(214, 214, 214);
      text-align: center;
    }
    th.service-name {
      width: 60%;
    }
    th.first-tier {
      color: #7baae2;
      width: 13%;
    }
    th.second-tier {
      color: $mainBlue;
      width: 13%;
    }
    th.third-tier {
      color: $mainBlack;
      width: 13%;
    }
    th {
      font-family: Montserrat, sans-serif;
      transform: rotate(90deg) translateX(-40px);
      text-align: right;
      font-weight: 600;
      font-size: 20px;
    }
  }
  .service-packages {
    max-width: 95%;
    flex-wrap: wrap;
    .pkg {
      min-height: auto;
      flex-basis: 100%;
      margin: 15px 0;
      p {
        font-size: 1.4rem;
        color: $mainTextColor;
        padding-bottom: 0;
      }
      h2 {
        text-align: center;
        margin: 10px;
        font-size: 1.7rem;
      }
    }
  }
  .service-card-container {
    display: flex;
    margin: auto;
    margin-top: $mainMargin;
    align-items: center;
    justify-content: space-between;
    max-width: 90%;
    flex-wrap: wrap;
    a.service-card {
      padding: 10px;
      background-color: $mainBlue;
      flex-basis: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 7px;
      transition: 0.2s;
      margin: 10px 0;
      transform: translateY(0px);
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0);
      img {
        width: 130px;
        margin-right: 20px;
      }
      p {
        flex-basis: 100%;
        color: white;
        font-size: 1.2rem;
        font-weight: 300;
        b {
          color: white;
          font-size: 17px;
          font-weight: 500;
        }
      }
      &:hover {
        background-color: $mainLightBlue;
        transition: 0.2s;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
        transform: translateY(-3px);
      }
    }
  }

  //Graphic Design
  .showcase-graphic-design {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 0;
    .service-cover-art {
      display: block;
      width: 45%;
      border-radius: 5px;
    }
    img:nth-child(1) {
      margin-right: 20px;
    }
  }
  #service-header-v2-more {
    font-family: $primaryFont;
    font-weight: 700;
    position: static;
    text-align: center;
    font-size: 2rem;
    padding: 0;
    width: 95%;
    margin: auto;
    margin-top: $mainMargin * 2;
    color: $mainTextColor;
    letter-spacing: 0.5px;
  }

    //Soundcloud
    .soundcloud-showcase{
      width:100%;
      .sound-snippet{
        width:100%;
        height: 170px;
        margin-bottom:80px;
        h3{
          margin: 0 0 7px 3px;
          color: $mainBlack;
          width: 100%;
        }
      }
      .sound-snippet:last-child{
        margin-bottom:0;
      }
      iframe{
        width: 100%;
        //width needs to be same as .sound-snippet
      }
    }
}

// ---- Mobile
@media only screen and (max-width: 730px) {
  //iFrame Audiogram
  #audiogram-videos {
    width: 95%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .iframe-wrapper {
      margin: $mainMargin 0 0 0;
      padding: 0px;
      //padding-bottom: 56.2%;
      width: 100%;
      height: 0px;
      padding-bottom: 100%;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }
  }

  //HOME PAGE
  .home_row_wide {
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    border-bottom: solid 10px $mainYellow;
    background-color: $mainBlue;
  }
  .no_display_mobile {
    display: none;
  }
  .home_column {
    flex-basis: 100%;
  }
  .bluespec {
    background-image: none;
  }
  .biglogo {
    display: none;
  }
  .home-biglogo-gray {
    display: block;
    opacity: 1;
    z-index: -1;
    width: 80%;
    margin: 20px auto 0 auto;
    transform: translateX(-5px);
  }
  .scrollicon {
    display: none;
  }
  .home_opening_bg {
    background: none;
    display: flex;
    align-items: center;
  }
  .home_opening {
    width: 90%;
    margin: auto;
    padding: 0;
    z-index: 1;
    h1 {
      font-size: 1.6rem;
      padding: 30px 0;
      font-weight: 600;
      letter-spacing: -1px;
      color: rgb(31, 31, 31);
    }
  }
  .brush {
    background: none;
    min-height: auto;
    padding: 50px 0;
  }
  .main_body_content {
    margin: 0px auto;
    width: 90%;
    padding: 0;
  }
  .flex-row-has-2 {
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    h1 {
      color: $mainBlue;
    }
    p {
      margin: 20px auto;
      font-size: 1.5rem;
      color: $mainTextColor;
    }
    .column:first-child {
      flex-basis: 100%;
      margin-right: 0;
      display: block;
    }
    .column:nth-child(2) {
      min-width: 100%;
      flex-basis: 100%;
      display: block;
    }
    img {
      border-radius: 10px;
      overflow: hidden;
    }
  }
  .bluebar {
    background-color: #4980e2;
    height: 15px;
    width: 100%;
    margin: 40px auto;
    border-radius: 3px;
  }

  /* Old Service Page*/
  .spacer {
    margin: 30px auto;
  }
  .service-flex .service-bio:first-child {
    margin-right: 0;
    font-size: 1.3rem;
    color: $mainTextColor;
    min-width: 100%;
  }
  .borderbullet {
    font-size: 1.3rem;
    font-family: Roboto;
    line-height: 1.4em;
    color: #666666;
    align-self: center;
    border-radius: 6px;
    border: 3px solid #4980e2;
    padding: 13px 0px;
    padding-left: 30px;
    margin: 0px;
    height: 100%;
    transform: translateY(0px);
    margin-top: 0px;
    flex: none;
    width: 90%;
    margin-top: 10px;
    box-shadow: 4px 4px 1px rgba(0, 0, 0, 0.2);
  }
  .yellowbarsmall {
    height: 10px;
    border-radius: 2px;
    background-color: $mainYellow;
    width: 100%;
  }
  .service-flex {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
  }
  .old-service-title {
    color: $mainBlue;
    font-size: 2rem;
    margin-bottom: $mainMargin/2;
  }
  .old-service {
    h3 {
      margin-bottom: 6px;
    }
    p {
      font-size: 1.3rem;
      color: $mainTextColor;
    }
  }
  p.asterisk {
    font-style: italic;
    font-size: 1.3rem;
    margin-top: $mainMargin/4;
  }
  .old-service-pkg {
    margin: 40px auto;
  }
  #back-to-top-btn {
    right: 5%;
    bottom: 5%;
    transform: translateX(0) translateY(0);
  }

  //Service Specific
  .service-card-container {
    display: flex;
    margin: auto;
    margin-top: $mainMargin;
    align-items: center;
    justify-content: space-between;
    max-width: 95%;
    flex-wrap: wrap;
    a.service-card {
      padding: 10px;
      background-color: $mainBlue;
      flex-basis: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 7px;
      transition: 0.2s;
      margin: 10px 0;
      transform: translateY(0px);
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0);
      img {
        width: 130px;
        margin-right: 8px;
      }
      p {
        flex-basis: 100%;
        color: white;
        padding: 3px;
        font-size: 1.2rem;
        font-weight: 300;
        b {
          color: white;
          font-size: inherit;
          font-weight: 500;
        }
      }
      &:hover {
        background-color: $mainLightBlue;
        transition: 0.2s;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
        transform: translateY(-3px);
      }
    }
  }
  //Service Gray Section
  .service-gray {
    background-color: #f2f2f2;
    width: 100%;
    margin-top: $mainMargin;
    padding: $mainMargin 5%;
    h1.service-title {
      margin-top: 0;
    }
    h2 {
      font-size: 2rem;
    }
    p {
      font-size: 1.3rem;
    }
  }
  .service-packages {
    display: flex;
    margin: auto;
    margin-top: $mainMargin/1.8;
    align-items: stretch;
    justify-content: space-between;
    max-width: 95%;
    flex-wrap: wrap;
    .pkg {
      background-color: white;
      border-radius: 12px;
      margin: 15px 0;
      padding: 10px;
      min-height: auto;
      flex-basis: 100%;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
      h2 {
        text-align: center;
        margin-bottom: 25px;
        margin-top: 20px;
      }
      p {
        font-size: 18px;
        color: $mainTextColor;
        margin-bottom: 20px;
      }
    }
  }

  //Comparison Chart
  .comparison-chart {
    width: 95%;
    margin: auto;
    p {
      font-size: 15px;
      font-weight: 400;
      font-style: italic;
      margin-top: $mainMargin;
      color: $mainTextColor;
      opacity: 0.8;
    }
  }
  table.podcast-comparison {
    margin-top: $mainMargin * 2;
    padding: 0;
    border-collapse: collapse;
    table-layout: auto;
    width: 100%;
    td,
    th {
      padding: 12px 0;
      .fa-check {
        font-size: 18px;
      }
    }
    td:nth-child(1) {
      text-align: left;
      color: $mainTextColor;
      font-family: Roboto, sans-serif;
      font-weight: 500;
      font-size: 1.1rem;
    }
    td:nth-child(2) {
      color: #b5ef59;
    }
    td:nth-child(3) {
      color: #a6dd47;
    }
    td:nth-child(4) {
      color: #82c91e;
    }
    tr {
      border-bottom: 1px solid rgb(214, 214, 214);
      text-align: center;
    }
    th.first-tier {
      color: #7baae2;
    }
    th.second-tier {
      color: $mainBlue;
    }
    th.third-tier {
      color: $mainBlack;
    }
    th {
      font-family: Montserrat, sans-serif;
      text-align: right;
      font-weight: 600;
      font-size: 20px;
      transform: rotate(90deg) translateX(-18px);
    }
  }
  div.hero-p {
    text-align: center;
    line-height: 1.3em;
    font-size: 1.3rem;
    width: 95%;
    font-family: Roboto, sans-serif;
    color: $mainTextColor;
    margin: auto;
    .icon-open {
      font-size: 1.3em;
      color: $mainBlue;
      margin-left: 5px;
    }
    .icon-closed {
      font-size: 1.3em;
      color: $mainBlue;
      margin-left: 5px;
    }
  }
  h1.service-title {
    font-family: Montserrat, "sans-serif";
    font-weight: 600;
    color: $mainBlack;
    font-size: 1.8rem;
    padding: 0 20px;
    margin-top: $mainMargin/2;
    text-align: center;
  }
  .cal-btn {
    background-color: rgb(133, 54, 235);
    display: block;
    color: white;
    transition: 0.2s;
    &:hover {
      color: white;
      background-color: $mainBlue;
      transition: 0.1s;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
      transform: translateY(-3px);
    }
    i {
      font-size: 1em;
    }
    padding: 10px;
    max-width: 100%;
    margin: 20px auto;
    border-radius: 10px;
  }
  .horizontal-pkg {
    width: 100%;
    padding: 10px 0px;
  }
  div.service-title {
    p {
      max-width: 90%;
      margin: auto;
      margin-top: 10px;
    }
    h1 {
      font-size: 1.7rem;
    }
    h2 {
      font-size: 1.4rem;
    }
  }
  .showcase-one-sample {
    max-width: 90%;
    h2 {
      font-size: 1.2rem;
    }
    img {
      width: 55%;
    }
  }
  .showcase-links {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: stretch;
    max-width: 90%;
    font-family: Roboto, sans-serif;
    margin: auto;
    margin-top: $mainMargin/2;
    a.cards {
      background-color: $mainBlue;
      color: white;
      border-radius: 5px;
      padding: 12px;
      margin: 6px auto;
      flex-basis: 100%;
      @include center-flex();
    }
  }

  //ABout
  .about-row {
    img {
      width: 100%;
    }
  }
  .yellow-bar {
    margin: 5px auto;
    height: 10px;
  }

  //More Service
  #service-header-v2 {
    font-family: $primaryFont;
    font-weight: 700;
    position: static;
    font-size: 1.3rem;
    padding: 0;
    width: 95%;
    margin: auto;
    margin-top: $mainMargin;
    color: $mainTextColor;
    letter-spacing: 0.5px;
  }

  .service-another-separate {
    width: auto;
    margin-top: $mainMargin * 2;
    margin-bottom: $mainMargin * 2;
    border-radius: 0;
    padding: 20px;
    border: none;
    background-color: $mainBlue;
    h2 {
      font-size: 2rem;
      color: white;
    }
    mark {
      background-color: #4d95e7;
      border-radius: 0;
    }
    p {
      font-size: 0.8rem;
      color: white;
      font-size: 1.4rem;
    }
  }
  .audio-box {
    @include center-flex;
    width: 100%;
    margin: auto;
    flex-wrap: wrap;
    flex-direction: column;
    padding-bottom: 10px;
    audio {
      display: block;
      margin: 10px auto;
    }
    h3 {
      margin: 0 0 20px 0;
      color: $mainBlue;
      margin-top: 20px;
      text-align: center;
    }
  }
  .showcase-graphic-design {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 0;
    .service-cover-art {
      display: block;
      width: 100%;
      border-radius: 5px;
    }
    .service-cover-art:nth-child(1) {
      margin-right: 0px;
      margin-bottom: 20px;
    }
    .classic-card:nth-child(1) {
      margin-right: 0;
    }
  }

  //transcriptions
  .classic-card {
    width: 100%;
    margin-top: 60px;
    a {
      margin-top: 15px;
      font-size: 1.3rem;
      font-weight: 600;
      font-family: $primaryFont;
      display: block;
      text-align: center;
    }
    img.service-graphic-art {
      width: 100%;
      height: auto;
    }
  }
  .classic-card:nth-child(1) {
    margin-top: 0;
  }

  .stylescape-wrapper {
    flex-direction: column;
    width: 100%;
    margin: auto;
    margin-top: $mainMargin;
    .service-stylescape {
      width: 100%;
      border-radius: 2px;
      margin-bottom: 20px;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }
    .service-stylescape:last-child {
      margin-bottom: 0;
    }
  }
  #service-header-v2-more {
    font-family: $primaryFont;
    font-weight: 700;
    position: static;
    font-size: 1.6rem;
    text-transform: uppercase;
    padding: 0;
    margin: auto;
    width: 95%;
    margin-top: $mainMargin * 1.5;
    color: $mainTextColor;
    text-align: center;
    letter-spacing: 1px;
  }

  //Service Swipper
  .swiper-container {
    width: 95%;
    border-radius: 10px;
    margin-top: $mainMargin * 2;
    margin-bottom: $mainMargin * 2;
    img.quote-svg {
      position: absolute;
      top: 20px;
      left: 5%;
      height: 14%;
      z-index: -100;
      opacity: 0.1;
    }
    .quote {
      font-size: 1.2rem;
    }
    .name {
      margin-top: $mainMargin / 2;
      font-family: $primaryFont;
      text-align: center;
      font-weight: 500;
      font-size: 1.5rem;
      color: $mainBlue;
    }

    --swiper-theme-color: #4980e2;
    --swiper-navigation-size: 34px;

    .swiper-button-next,
    .swiper-button-prev {
      padding: 30px 20px;
      position: absolute;
      top: 78%;
      width: calc(var(--swiper-navigation-size) / 44 * 27);
      height: var(--swiper-navigation-size);
      margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
      z-index: 999;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--swiper-navigation-color, var(--swiper-theme-color));
    }
  }
  .swiper-slide {
    padding: 0 20px;
  }


  //Soundcloud
  .soundcloud-showcase{
    width:100%;
    .sound-snippet{
      width:100%;
      height: 170px;
      margin-bottom:80px;
      h3{
        margin: 0 0 7px 3px;
        color: $mainBlack;
        width: 100%;
      }
    }
    .sound-snippet:last-child{
      margin-bottom:0;
    }
    iframe{
      width: 100%;
      //width needs to be same as .sound-snippet
    }
  }
}
