.form-body {
  width: 800px;
  margin: auto;
  padding: 0;
  margin-top: 20px;
}

.form-package-container {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  width: 820px;
  margin: $mainMargin auto;
  padding: 40px 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.form-black-simple-titles {
  text-align: left;
  margin: $mainMargin * 1.3 auto 20px auto;
  h2 {
    font-size: 2.4em;
    color: $mainTextColor;
  }
  h3 {
    margin-top: $mainMargin/2;
    font-size: 1.5em;
    color: $mainTextColor;
  }
  p {
    font-size: 1.2em;
    line-height: 1.25em;
  }
  .price-of-package {
    margin-bottom: 20px;
    text-align: center;
    span {
      font-style: normal;
    }
  }
  h1 {
    text-align: center;
    font-size: 2.5em;
  }
}

.form-package-description {
  line-height: 1.3em;
  font-size: 1.1em;
  width: 500px;
  display: block;
  list-style-type: none;
  li {
    padding: 10px 0;
    .fa-question-circle {
      color: rgb(106, 139, 150);
    }
  }
  li.inactive {
    color: #d3d3d3;
  }
}

label {
  margin-top: 20px;
}

form {
  font-size: 1.1em;
  color: $mainTextColor;
}

input[type="text"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0 20px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 0.9em;
}

input[type="email"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0 20px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 0.9em;
}

input[type="submit"] {
  width: 100%;
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0 0 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
}
input[type="reset"] {
  width: 100%;
  background-color: #c2c2c2;
  color: rgb(61, 61, 61);
  padding: 14px 20px;
  margin: 8px 0 0 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
}
input[type="reset"]:hover {
  background-color: #b3b3b3;
}

textarea {
  display: block;
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0 20px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 0.9em;
}

.form-caption {
  margin-bottom: 20px;
}

input[type="submit"]:hover {
  background-color: #45a049;
}

p.form-package-selection {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0 20px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: rgb(243, 243, 243);
  box-sizing: border-box;
  cursor: not-allowed;
  span {
    color: rgb(54, 161, 54);
    font-style: normal;
  }
}

.checkboxes {
  margin-bottom: 50px;
  line-height: 1.8em;
  padding: 9px;
  border-radius: 4px;
  border: 1px solid rgb(202, 202, 202);
  h4 {
    margin-bottom: 10px;
  }
}

.pricing-non-standard {
  margin-top: $mainMargin * 2;
}

// --- Tablet View
@media (min-width: 731px) and (max-width: 1024px) {
  .form-package-container {
    width: 90%;
  }
  .form-black-simple-titles {
    width: 100%;
  }
  .form-body {
    width: 90%;
  }
  .form-package-description {
    width: 100%;
  }
}

// ---- Mobile
@media only screen and (max-width: 730px) {
  .form-package-container {
    width: 90%;
  }
  .form-black-simple-titles {
    width: 100%;
  }
  .form-body {
    width: 90%;
  }
  .form-package-description {
    width: 100%;
  }
}
