// --- Navbar Nav Links ---
header.navbar {
  min-height: 9vh;
  width: 100%;
  background-color: $navColor;
  display: flex;
  z-index: 9999;
}
nav.nav {
  width: 96%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
}
.nav-logo {
  a {
    font-family: $primaryFont;
    color: $navTextColor;
    @include center-flex;
    font-size: 1.7rem;
    font-weight: 600;
    letter-spacing: 2px;
  }
  img {
    height: 50px;
    margin-right: 10px;
    padding: 5px;
  }
}
.nav-links {
  display: flex;
  align-items: stretch;
  justify-content: center;
  a {
    color: $navTextColor;
    height: 9vh;
    position: relative;
    padding: 0 25px;
    @include center-flex;
    font-size: 1.1rem;

    &:hover {
      color: $navHoverColor;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 3px;
      background: $navHoverColor;
      width: 100%;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform 0.2s;
    }

    &:hover:before {
      transform: scaleX(1);
      transition: transform 0.2s;
      transform-origin: left;
    }
  }
  a.header-social-media {
    font-size: 1.6rem;
    padding: 0px 25px;
  }
}
.burger {
  display: none;
  cursor: pointer;
}
.burger div {
  width: 25px;
  height: 3px;
  background-color: $burgerColor;
  margin: 5px;
  transition: all 0.3s ease;
}
@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

.backto-team-members {
  background-color: $mainBlue;
  margin: auto;
  color: white;
  font-weight: 600;
  font-size: 1.5rem;
  width: 400px;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  margin-top: $mainMargin;
  display: block;
  transition: 0.2s;
  font-family: $primaryFont;
  &:hover {
    transition: 0.2s;
    transform: translateY(-3px);
    color: rgb(32, 32, 32);
    background-color: $mainYellow;
  }
}

// --- Footer ---
$footerColor: coral;
$footerLinkColor: rgb(250, 216, 67);

footer {
  background-color: $navColor;
  color: rgb(230, 230, 230);
  .flex-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
    padding-top: 20px;
    width: 80%;
    margin: auto;
    text-align: center;
  }
  a {
    position: relative;
    color: $footerLinkColor;
    &:after {
      content: "";
      position: absolute;
      bottom: -0.1em;
      left: 0;
      width: 100%;
      height: 2px;
      transform: scaleX(0);
      background-color: $footerLinkColor;
      transition: 0.05s;
    }
    &:hover:after {
      color: $footerLinkColor;
      transform: scaleX(1);
      transition: 0.05s;
    }
    &:hover {
      color: $footerLinkColor;
    }
  }
}
.social-media {
  text-align: center;
  padding-top: 20px;
  a {
    color: rgb(255, 255, 255);
    font-size: 1.9rem;
    margin: 0 10px;
  }
}

//Service Nav Menu
.service-nav {
  display: flex;
  max-width: 1100px;
  margin: auto;
  margin-top: $mainMargin;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  a {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 600;
    font-size: 1.4rem;
    color: $mainTextColor;
    border-radius: 5px;
    text-transform: uppercase;
    text-align: center;
    min-height: 140px;
    flex-basis: 22%;
    margin: 20px 7px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    border: 4px solid $mainYellow;
    height: 100%;
    line-height: 1.1em;
    transition: 0.1s;
    &:hover {
      background-color: $mainYellow;
      transition: 0.1s;
      color: $mainTextColor;
    }
  }
}
.service-nav-v3 {
  display: flex;
  max-width: 1100px;
  margin: auto;
  margin-top: $mainMargin;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  a {
    font-family: $secondaryFont;
    font-weight: 400;
    font-size: 1.4rem;
    color: white;
    border-radius: 5px;
    text-transform: capitalize;
    text-align: center;
    min-height: 120px;
    flex-basis: 22%;
    margin: 25px 7px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $mainBlue;
    letter-spacing: 0.5px;
    height: 100%;
    line-height: 1.1em;
    transition: 0.1s;
    &:hover {
      background-color: #5b91ee;
      transition: 0.1s;
    }
  }
}
.service-nav-v4 {
  display: flex;
  max-width: 1100px;
  margin: auto;
  margin-top: $mainMargin;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  a {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 500;
    font-size: 1.4rem;
    color: white;
    border-radius: 5%;
    text-transform: capitalize;
    text-align: center;
    min-height: 150px;
    flex-basis: 22%;
    margin: 30px 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4653c5;
    letter-spacing: 1px;
    height: 100%;
    line-height: 1.1em;
    transition: 0.1s;
    &:hover {
      background-color: rgb(36, 36, 36);
      transition: 0.1s;
      color: $mainYellow;
    }
  }
}
.service-nav-v2 {
  display: flex;
  width: 650px;
  position: relative;
  left: 26%;
  margin-top: $mainMargin;
  flex-direction: column;
  a {
    font-size: 1.8rem;
    color: $mainTextColor;
    font-weight: 600;
    text-transform: uppercase;
    font-family: $primaryFont;
    letter-spacing: 1px;
    margin: 0;
    padding: 10px 0;
    position: relative;
    transition: 0.2s;
    transform: translateX(0px);
    &:after {
      content: "";
      height: 5px;
      opacity: 0.5;
      width: 300px;
      position: absolute;
      background-color: $mainBlue;
      top: 45%;
      border-radius: 2px;
      margin-left: 10px;
      transition: 0.5s ease-out;
      transform: scaleX(0);
      transform-origin: left;
    }
    &:hover:after {
      transform: scaleX(1);
      opacity: 1;
    }
    &:hover {
      color: $mainBlue;
      transform: translateX(3px);
    }
  }
}
.service-nav-v2-container {
  position: relative;
  padding-bottom: $mainMargin * 2;
}
.service-biglogo-yellow {
  position: absolute;
  z-index: -999;
  top: 0;
  transform: translateY(-13%);
  right: 10%;
  max-width: 50vw;
  padding: 0;
  max-height: 110%;
  opacity: 0.35;
  overflow: hidden;
}
.error-page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80vh;
  h1 {
    margin-bottom: 30px;
  }
}
.parent-contact-bubbles-container {
  padding: 60px 0 60px 0;
  background-color: #f2f2f2;
}

.contact-bubbles-container {
  width: 550px;
  margin: auto;
  text-align: center;
  .contact-bubbles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: auto;
    a {
      display: block;
      background-color: $mainPurple;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 50%;
      text-transform: uppercase;
      font-family: $primaryFont;
      padding: 20px;
      font-weight: 800;
      box-shadow: 7px 7px 45px -12px rgba(0, 0, 0, 0.7);
      &:hover {
        transition: 0.1s;
        background-color: $mainBlue;
      }
    }
    i {
      margin-bottom: 8px;
    }
    .schedule-time {
      width: 250px;
      height: 250px;
      font-size: 35px;
      transform: rotate(-6deg);
      transition: 0.2s;
    }
    .email {
      width: 150px;
      height: 150px;
      font-size: 25px;
      transform: rotate(6deg);
    }
  }
}
.contact-bubbles-container-gray {
  width: 100%;
  margin: auto;
  text-align: center;
  padding: 100px 0;
  background-color: #f2f2f2;
  .contact-bubbles {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 500px;
    margin: auto;
    a {
      display: block;
      background-color: $mainPurple;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 50%;
      text-transform: uppercase;
      font-family: $primaryFont;
      padding: 20px;
      font-weight: 800;
      box-shadow: 7px 7px 45px -12px rgba(0, 0, 0, 0.7);
      &:hover {
        transition: 0.1s;
        background-color: $mainBlue;
      }
    }
    i {
      margin-bottom: 8px;
    }
    .schedule-time {
      width: 250px;
      height: 250px;
      font-size: 35px;
      transform: rotate(-6deg);
      transition: 0.2s;
    }
    .email {
      width: 150px;
      height: 150px;
      font-size: 25px;
      transform: rotate(6deg);
    }
  }
}
.home-only-contact-bubbles {
  transform: translateY(-100px);
}
a#bbblink.ruhzbam,
a#bbblink.ruhzbum {
  width: 150px;
  height: 69px;
  margin: auto;
  margin-top: 20px;
}

@media (min-width: 731px) and (max-width: 1024px) {
  header.navbar {
    z-index: 999;
    height: 70px;
  }
  .nav-links {
    position: absolute;
    padding: 0;
    z-index: 999;
    margin: 0;
    right: 0px;
    height: auto;
    top: 69px;
    background-color: $navColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 0;
    transition: max-width 0.5s ease;
    padding-top: 15px;
    padding-bottom: 15px;
    overflow: hidden;
    border-radius: 0px 0 1% 5%;
    a {
      margin: 15px;
      opacity: 0;
      text-align: center;
      width: 800px;
      &:after {
        display: none;
      }
    }
    .header-social-media {
      display: none;
    }
  }
  .burger {
    display: block;
  }
  .nav-active {
    max-width: 40vw;
  }
  footer {
    flex-wrap: nowrap;
    padding: 0;
    .flex-footer {
      padding: 30px 0;
    }
    div {
      flex-basis: 100%;
      margin: 0;
    }
    a {
      flex-basis: 100%;
      margin: 0 auto;
      &:hover:after {
        color: $footerLinkColor;
        transform: scaleX(0);
      }
    }
  }
  .service-nav {
    display: flex;
    max-width: 98%;
    margin: auto;
    margin-top: $mainMargin/4;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    a {
      font-family: "Roboto Condensed", sans-serif;
      margin: 10px 5px 5px 5px;
      font-weight: 600;
      font-size: 17px;
      color: $mainTextColor;
      border-radius: 5px;
      padding: 2px;
      text-transform: uppercase;
      text-align: center;
      min-height: 120px;
      flex-basis: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0);
      border: 3px solid $mainYellow;
      height: 100%;
      line-height: 1.1em;
      transition: 0.1s;
      letter-spacing: 0px;
      &:hover {
        background-color: $mainYellow;
        transition: 0.1s;
        color: $mainTextColor;
      }
    }
  }
  .service-nav-v3 {
    display: flex;
    max-width: 98%;
    margin: auto;
    margin-top: $mainMargin/4;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    a {
      font-family: "Roboto Condensed", sans-serif;
      margin: 10px 5px 5px 5px;
      font-weight: 600;
      font-size: 17px;
      color: $mainTextColor;
      border-radius: 5px;
      padding: 2px;
      text-transform: uppercase;
      text-align: center;
      min-height: 120px;
      flex-basis: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0);
      border: 3px solid $mainYellow;
      height: 100%;
      line-height: 1.1em;
      transition: 0.1s;
      letter-spacing: 0px;
      &:hover {
        background-color: $mainYellow;
        transition: 0.1s;
        color: $mainTextColor;
      }
    }
  }
  .service-nav-v2 {
    display: flex;
    width: 95%;
    margin: $mainMargin auto 0 auto;
    flex-direction: column;
    position: static;
    a {
      font-size: 1.7rem;
      color: $mainBlue;
      font-weight: 600;
      text-transform: uppercase;
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      font-family: $primaryFont;
      position: static;
      letter-spacing: 1px;
      margin: 0;
      padding: 10px 0;
      transition: 0.2s;
      transform: translateX(0px);
      &:after {
        display: none;
      }
      &:hover {
        color: $mainBlue;
        transform: translateX(0);
      }
    }
  }
  .service-nav-v2-container {
    position: relative;
  }
  .service-biglogo-yellow {
    display: none;
  }
  .contact-bubbles-container {
    width: 60%;
    .contact-bubbles {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 90%;
      margin: auto;
      .schedule-time {
        width: 200px;
        height: 200px;
        font-size: 25px;
      }
      .email {
        width: 130px;
        height: 130px;
        font-size: 20px;
      }
    }
  }
  .home-only-contact-bubbles {
    transform: translateY(0);
    margin-top: 50px;
  }
}

//--- Mobile for All Components ---
@media only screen and (max-width: 730px) {
  header.navbar {
    z-index: 999;
  }
  .nav-links {
    position: absolute;
    padding: 0;
    z-index: 999;
    margin: 0;
    right: 0px;
    height: auto;
    top: 9vh-0.1;
    background-color: $navColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 0;
    transition: max-width 0.5s ease;
    padding-top: 15px;
    padding-bottom: 15px;
    overflow: hidden;
    border-radius: 0px 0 1% 5%;
    a {
      margin: 15px;
      opacity: 0;
      text-align: center;
      width: 800px;
      &:after {
        display: none;
      }
    }
    .header-social-media {
      display: none;
    }
  }
  .burger {
    display: block;
  }
  .nav-active {
    max-width: 60vw;
  }
  footer {
    font-size: 1.2rem;
    .flex-footer {
      flex-wrap: wrap;
      padding: 0;
    }
    div {
      flex-basis: 100%;
      margin: 0;
    }
    a {
      flex-basis: 100%;
      margin: 30px auto;
      &:hover:after {
        color: $footerLinkColor;
        transform: scaleX(0);
      }
    }
  }
  .backto-team-members {
    width: 95vw;
    padding: 15px 0;
    border-radius: 5px;
  }
  //Service Navigation Table
  .service-nav {
    display: flex;
    max-width: 96%;
    margin: auto;
    margin-top: $mainMargin/4;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    a {
      font-family: "Roboto Condensed", sans-serif;
      margin: 0;
      font-weight: 600;
      font-size: 1.3rem;
      color: $mainTextColor;
      border-radius: 5px;
      padding: 2px;
      text-transform: uppercase;
      text-align: center;
      min-height: 120px;
      flex-basis: 48.5%;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0);
      border: 3px solid $mainYellow;
      height: 100%;
      line-height: 1.1em;
      transition: 0.1s;
      letter-spacing: 0px;
      &:hover {
        background-color: $mainYellow;
        transition: 0.1s;
        color: $mainTextColor;
      }
    }
  }
  .service-nav-v2 {
    display: flex;
    width: 95%;
    margin: $mainMargin auto 0 auto;
    flex-direction: column;
    position: static;
    a {
      font-size: 1.5rem;
      color: $mainBlue;
      font-weight: 600;
      text-transform: uppercase;
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      font-family: $primaryFont;
      position: static;
      letter-spacing: 1px;
      margin: 0;
      padding: 10px 0;
      transition: 0.2s;
      transform: translateX(0px);
      &:after {
        display: none;
      }
      &:hover {
        color: $mainBlue;
        transform: translateX(0);
      }
    }
  }
  .service-nav-v2-container {
    position: relative;
  }
  .service-biglogo-yellow {
    display: none;
  }
  .contact-bubbles-container {
    width: 90%;
    .contact-bubbles {
      width: 100%;
      .schedule-time {
        width: 50vw;
        height: 50vw;
        font-size: 2.2em;
        transform: translateX(5vw) rotate(-6deg);
      }
      .email {
        width: 30vw;
        height: 30vw;
        font-size: 1.8em;
        transform: translate(-5vw, 80px) rotate(6deg);
      }
    }
  }
  .home-only-contact-bubbles {
    transform: translateY(0);
    margin-top: 50px;
  }
  .parent-contact-bubbles-container {
    padding: 60px 0 100px 0;
  }
}
