$headerBlack: #272727;

.home-first-row {
  width: 74%;
  height: 80vh;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin: 50px auto 0 auto;
  transform: translateX(50px);
  &__introduction {
    flex-basis: 40%;
    margin-right: 0px;
    h1 {
      letter-spacing: 1px;
      font-size: 2em;
      text-transform: uppercase;
      font-weight: 800;
      line-height: 1.4em;
      color: $headerBlack;
      margin-bottom: 30px;
      span {
        font-size: inherit;
        font-style: inherit;
        font-weight: inherit;
        font-family: inherit;
        position: relative;
        color: $mainBlue;
        &:after {
          content: "";
          position: absolute;
          width: 104%;
          height: 3px;
          border-radius: 1px;
          background-color: $mainBlue;
          left: -2%;
          bottom: 3%;
        }
      }
    }
    p {
      font-size: 1.3em;
      color: $bodyBlack;
    }
  }
  &__image {
    flex-basis: 48%;
    img {
      width: 100%;
    }
  }
}
.h1 {
  letter-spacing: 1px;
  font-size: 2em;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 1.4em;
  color: $headerBlack;
  margin-bottom: 30px;
  span {
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-family: inherit;
    position: relative;
    color: $mainBlue;
    &:after {
      content: "";
      position: absolute;
      width: 104%;
      height: 3px;
      border-radius: 1px;
      background-color: $mainBlue;
      left: -2%;
      bottom: 3%;
    }
  }
}
a.button-basic {
  display: inline-block;
  font-size: 1.3em;
  letter-spacing: 1px;
  font-weight: 700;
  border-radius: 8px;
  margin-top: $mainMargin;
  background-color: $mainPurple;
  padding: 15px 25px;
  color: white;
  font-family: $primaryFont;
  text-transform: uppercase;
  transition: 0.3s;
  box-shadow: 7px 7px 45px -12px rgba(0, 0, 0, 0.7);
  &:hover {
    transition: 0.14s;
    background-color: $mainBlue;
    box-shadow: 18px 18px 49px -12px rgba(0, 0, 0, 0.59);
  }
}
.home-gray-bubble-container {
  position: relative;
  z-index: -999;
  background-color: #f2f2f2;
  transform: translateY(-20vh);
  margin-top: 0;
  padding: 0;
  h3 {
    margin-bottom: 0.5em;
    font-size: 1.7rem;
    font-weight: 800;
    color: $mainBlack;
    text-transform: uppercase;
  }
  p,
  li {
    font-size: 1.3em;
    color: $bodyBlack;
  }
  ul {
    list-style-type: none;
    li {
      text-transform: capitalize;
      background: url("/assets/img/green-check.svg") no-repeat left top;
      height: 1.1em;
      padding-left: 44px;
      padding-top: 0px;
      margin-top: 4px;
    }
  }
  &__bubble-top {
    background-color: white;
  }
  &__bubble-bottom {
    background-color: #f2f2f2;
    margin-bottom: -5px;
    padding-bottom: 0px;
    margin-top: 150px;
  }
  &__spacer {
    height: 1px;
    margin-top: 20vh;
  }
  &__content {
    width: 1300px;
    margin: auto;
    position: relative;
    #mic {
      position: absolute;
      width: 230px;
      left: 0;
      top: 0;
      filter: drop-shadow(7px 7px 15px rgba(0, 0, 0, 0.15));
    }
    .content {
      width: 600px;
      margin: auto;
      &__shift-left {
        width: 70%;
        ul {
          margin-bottom: 3em;
        }
      }
    }
    #podcast-table {
      position: absolute;
      width: 400px;
      right: 0;
      bottom: 0;
      filter: drop-shadow(7px 7px 15px rgba(0, 0, 0, 0.15));
    }
  }
}
section.FAQ-container {
  width: 50%;
  margin: auto;
  margin-bottom: 100px;
  .accordion {
    background-color: #dddddd;
    border-radius: 8px;
    .accordian-item {
      &:not(:last-child) {
        border-bottom: 1px solid rgb(78, 78, 78);
      }
      a.accordian-link {
        font-family: $primaryFont;
        padding: 20px;
        font-weight: 600;
        font-size: 1.5em;
        color: $mainBlack;
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        i {
          color: rgb(51, 51, 51);
        }
        .fa-chevron-circle-up {
          display: none;
        }
      }
      .answer {
        max-height: 0;
        padding: 0;
        overflow: hidden;
        background-color: #f2f2f2;
        padding-right: 5%;
        padding-left: 20px;
        transition: 0.4s;
        p {
          font-size: 1em;
          font-family: $secondaryFont;
          color: #2d2d2d;
          font-weight: 500;
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }
    .accordian-item:target .answer {
      max-height: 10rem;
      transition: 1s;
    }

    .accordian-item:target .accordian-link .fa-chevron-down {
      transform: rotate(180deg);
      transition: 0.3s;
    }
  }
}


//TABLET
@media (min-width: 731px) and (max-width: 1024px) {
  .home-first-row {
    width: 90%;
    height: auto;
    transform: translateX(0);
  }
  .home-gray-bubble-container {
    &__spacer {
      margin-top: 50px;
    }
    &__bubble-bottom {
      margin-top: 50px;
    }
    transform: translateY(0vh);
    &__content {
      width: 100%;
      #mic {
        display: none;
      }
      .content {
        width: 50%;
        margin-left: 5%;
      }
      #podcast-table {
        width: 35vw;
        right: 10%;
        bottom: 10%;
      }
    }
  }
  section.FAQ-container {
    width: 80%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 100px;
    .accordion {
      background-color: #dddddd;
      border-radius: 8px;
      .accordian-item {
        &:not(:last-child) {
          border-bottom: 1px solid rgb(78, 78, 78);
        }
        a.accordian-link {
          font-family: $primaryFont;
          padding: 20px;
          font-weight: 600;
          font-size: 1.5em;
          color: $mainBlack;
          text-decoration: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          i {
            color: rgb(51, 51, 51);
          }
          .fa-chevron-circle-up {
            display: none;
          }
        }
        .answer {
          max-height: 0;
          padding: 0;
          overflow: hidden;
          background-color: #f2f2f2;
          padding-right: 5%;
          padding-left: 20px;
          transition: 0.4s;
          p {
            font-size: 1.2em;
            font-family: $secondaryFont;
            color: #2d2d2d;
            font-weight: 500;
            padding-top: 20px;
            padding-bottom: 20px;
          }
        }
      }
      .accordian-item:target .answer {
        max-height: 10rem;
        transition: 1s;
      }

      .accordian-item:target .accordian-link .fa-chevron-down {
        transform: rotate(180deg);
        transition: 0.3s;
      }
    }
  }
  .h1,
  h1.h1 {
    font-size: 1.8em;
  }
}

//MOBILE
@media only screen and (max-width: 730px) {
  .home-first-row {
    width: 90%;
    height: auto;
    flex-direction: column;
    transform: translateX(0);
    &__image {
      img {
        width: 100%;
      }
    }
  }
  .home-gray-bubble-container {
    margin-top: 40px;
    ul {
      list-style-type: disc;
      li {
        text-transform: capitalize;
        list-style-position: inside;
        background: none;
        height: auto;
        padding-left: 0;
        padding-top: 0;
        margin-top: 0;
      }
    }
    &__spacer {
      margin-top: 80px;
    }
    &__bubble-bottom {
      margin-top: 90px;
    }
    transform: translateY(0vh);
    &__content {
      width: 90%;
      #mic {
        display: none;
      }
      .content {
        width: 100%;
        margin-left: 0;
        &__shift-left {
          width: 100%;
        }
      }
      #podcast-table {
        display: none;
      }
    }
  }
  section.FAQ-container {
    width: 90%;
    margin-top: 130px;
    margin-bottom: 100px;
    .accordion {
      .accordian-item {
        a.accordian-link {
          padding: 15px;
          font-size: 1.5em;
          line-height: 1.3em;
          color: $mainBlack;
          text-decoration: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          i {
            color: rgb(51, 51, 51);
            margin-left: 10px;
          }
          .fa-chevron-circle-up {
            display: none;
          }
        }
        .answer {
          max-height: 0;
          padding: 0;
          overflow: hidden;
          background-color: #f2f2f2;
          padding-right: 5%;
          padding-left: 15px;
          transition: 0.4s;
          p {
            font-size: 1.2em;
            font-family: $secondaryFont;
            color: #2d2d2d;
            font-weight: 500;
            padding-top: 20px;
            padding-bottom: 20px;
          }
        }
      }
      .accordian-item:target .answer {
        max-height: 20rem;
        transition: 1s;
      }

      .accordian-item:target .accordian-link .fa-chevron-down {
        transform: rotate(180deg);
        transition: 0.3s;
      }
    }
  }
  .h1,
  h1.h1 {
    font-size: 1.8em;
    margin-bottom: 20px;
  }
  a.button-basic {
    display: block;
    text-align: center;
    font-size: 1.3em;
    letter-spacing: 1px;
    font-weight: 700;
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 40px;
    background-color: $mainPurple;
    padding: 15px 25px;
    color: white;
    font-family: $primaryFont;
    text-transform: uppercase;
    transition: 0.3s;
    box-shadow: 7px 7px 45px -12px rgba(0, 0, 0, 0.7);
    &:hover {
      transition: 0.14s;
      background-color: $mainBlue;
      box-shadow: 18px 18px 49px -12px rgba(0, 0, 0, 0.59);
    }
  }
}
